import React from 'react';

import { News } from '../../graphql/get-my-unpublished-news-list';
import UnpublishedNewsPreview from '../unpublished-news-preview';

interface UnpublishedNewsListProps {
  unpublishedNewsList: News[];
  openUnpublishedNews: (status: string, id: string) => void;
  deleteUnpublishedNews: () => void;
}

const UnpublishedNewsPreviewList: React.FC<UnpublishedNewsListProps> = ({
  unpublishedNewsList,
  openUnpublishedNews,
  deleteUnpublishedNews,
}) => {
  return (
    <>
      {unpublishedNewsList.map((news) => (
        <UnpublishedNewsPreview
          key={news.id}
          newsData={news}
          openUnpublishedNews={() => openUnpublishedNews(news.status, news.id)}
          deleteUnpublishedNews={deleteUnpublishedNews}
        />
      ))}
    </>
  );
};

export default UnpublishedNewsPreviewList;
