import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OneModeratedNewsDataType } from './get-moderation-news-list';
import { NewsFormPayloadType } from '../types/news-form-payload-type';

export interface UpdateModerateNewsMutation {
  updateModeratedNews:
    | {
        __typename: 'UpdateModeratedNews';
        news: OneModeratedNewsDataType;
      }
    | GraphqlErrorType;
}

export interface UpdateModerateNewsMutationVars {
  id: string;
  input: NewsFormPayloadType;
}

const UPDATE_MODERATED_NEWS = gql`
  mutation UpdateModeratedNews($id: ID!, $input: UpdateNewsInput!) {
    updateModeratedNews(id: $id, input: $input) {
      ... on UpdateModeratedNews {
        news {
          id
          title
          previewDescription
          previewImage
          previewVideo
          date
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          reviewInfo {
            id
            status
          }
          client {
            id
            name
            avatar
            iq
          }
          blocks {
            type
            id
            data {
              text
              caption
              alignment
              level
              items
              style
              stretched
              file {
                url
              }
              html
              url
              serviceName
            }
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPDATE_MODERATED_NEWS;
