import cx from 'classnames';
import React from 'react';

import { dateToTextShort } from 'application/utils/date';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './news-article-header.module.scss';
import { EventSportType } from '../../graphql/get-event-sports-list';
import { CategoryType } from '../../graphql/get-news-categories';

interface NewsArticleDetailsProps {
  sport: EventSportType;
  category: CategoryType;
  date: string;
  className?: string;
  children?: React.ReactNode;
}

const NewsArticleHeader: React.FC<NewsArticleDetailsProps> = ({
  sport,
  category,
  date,
  className,
  children,
}) => (
  <div className={cx(className && className, css.Root)}>
    <Flex gap={GridGap.x1} alignItems="center">
      <img className={css.SportIcon} src={sport.icon} alt={sport.name} />
      <p className={css.Sport}>{sport.name}</p>
    </Flex>

    <p className={css.Category}>{category.name}</p>
    <p className={css.Date}>{dateToTextShort(new Date(date))}</p>

    <div className={css.Actions}>{children}</div>
  </div>
);

export default NewsArticleHeader;
