import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OneModeratedNewsDataType } from './get-moderation-news-list';

export interface GetOneModeratedNewsQuery {
  getOneModeratedNews:
    | {
        __typename: 'GetOneModeratedNews';
        news: OneModeratedNewsDataType;
        myReview: boolean;
      }
    | GraphqlErrorType;
}

export interface GetOneModeratedNewsQueryVars {
  id: string;
}

const GET_ONE_MODERATED_NEWS = gql`
  query GetOneModeratedNews($id: ID!) {
    getOneModeratedNews(id: $id) {
      ... on GetOneModeratedNews {
        news {
          id
          title
          previewUploadDescription
          previewDescription
          previewImage
          previewVideo
          blocks {
            type
            id
            data {
              text
              caption
              alignment
              level
              items
              style
              stretched
              file {
                url
              }
              html
              url
              serviceName
            }
          }
          date
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
        }
        myReview
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_ONE_MODERATED_NEWS;
