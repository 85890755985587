import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useBodyOverflow from 'application/hooks/use-body-overflow';
import CrossIcon from 'ui/icons/cross';
import EditIcon from 'ui/icons/edit.svg';
import PhotoIcon from 'ui/icons/photo.svg';
import TrashIcon from 'ui/icons/trash.svg';

import css from './upload-client-avatar-mobile-menu.module.scss';

interface UploadClientAvatarMobileMenuProps {
  size: 'M' | 'L';
  avatar: string | null;
  modalOpen: () => void;
  deleteAvatar: () => void;
  closeMobileMenu: () => void;
}

const UploadClientAvatarMobileMenu: React.FC<
  UploadClientAvatarMobileMenuProps
> = ({ avatar, size, deleteAvatar, modalOpen, closeMobileMenu }) => {
  const [isFullAvatarOpen, setFullAvatarOpen] = useState(false);
  const { setOverflowed } = useBodyOverflow();
  const { t } = useTranslation(['common', 'account']);

  const wrapperOnClick = () => {
    isFullAvatarOpen ? closeFullAvatar() : closeMobileMenu();
  };

  const openFullAvatar = () => {
    setOverflowed(true);
    setFullAvatarOpen(true);
  };

  const closeFullAvatar = () => {
    setOverflowed(false);
    setFullAvatarOpen(false);
    closeMobileMenu();
  };

  return (
    <>
      <div
        className={cx(
          css.MobileMenuOverPage,
          isFullAvatarOpen && css.FullAvatarOverPage
        )}
        onClick={wrapperOnClick}
      >
        {avatar && isFullAvatarOpen && (
          <>
            <img
              src={avatar}
              className={css.FullAvatar}
              alt={t('common:alt:u_avatar')}
            />
            <CrossIcon className={css.Cross} onClick={closeFullAvatar} />
          </>
        )}
      </div>

      <div
        className={cx(
          css.MobileMenu,
          size === 'M' && css.AnimationM,
          size === 'L' && css.AnimationL
        )}
      >
        <button
          className={css.MenuItem}
          onClick={openFullAvatar}
          disabled={!avatar}
        >
          <PhotoIcon width={18} />
          {t('account:labels:see_avatar')}
        </button>

        <button className={css.MenuItem} onClick={modalOpen}>
          <EditIcon width={18} />
          {t('account:labels:change')}
        </button>

        <button
          className={css.MenuItem}
          onClick={deleteAvatar}
          disabled={!avatar}
        >
          <TrashIcon className={css.Trash} />
          {t('common:actions:delete')}
        </button>
      </div>
    </>
  );
};

export default UploadClientAvatarMobileMenu;
