import cx from 'classnames';
import React, { useRef } from 'react';

import { RemoveEditButtons } from 'ui';
import { Text } from 'ui/v2/typography/text';

import css from './news-preview.module.scss';
import { NewsPreviewDataType } from '../../types/new-preview-data-types';
import { sanitizeHtml } from '../../utils/sanitize-html';
import NewsArticleHeader from '../news-article-header';
import NewsPreviewImage from '../news-preview-image';
import NewsPreviewVideo from '../news-preview-video';

interface NewsPreviewProps {
  newsPreviewData: NewsPreviewDataType;
  editor: boolean;
  openNews: () => void;
  deleteNews?: () => void;
  editNews?: () => void;
  className?: string;
}

const NewsPreview: React.FC<NewsPreviewProps> = ({
  newsPreviewData: {
    sport,
    category,
    date,
    title,
    previewImage,
    previewDescription,
    previewVideo,
    previewUploadDescription,
  },
  openNews,
  deleteNews,
  editNews,
  editor,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isEditor = editor && deleteNews && editNews;

  return (
    <div ref={containerRef}>
      <NewsArticleHeader date={date} sport={sport} category={category}>
        {isEditor && <RemoveEditButtons remove={deleteNews} edit={editNews} />}
      </NewsArticleHeader>

      <div className={cx(className && className, css.Root)} onClick={openNews}>
        <h2
          className={css.Title}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
        />

        {previewDescription && (
          <p
            className={css.Description}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(previewDescription),
            }}
          />
        )}

        {previewImage && containerRef && (
          <NewsPreviewImage
            caption={previewUploadDescription || ''}
            previewImage={previewImage}
            containerRef={containerRef}
          />
        )}
        <div className={css.previewUploadDescription}>
          {previewVideo && <NewsPreviewVideo src={previewVideo} />}
          {previewVideo && previewUploadDescription && (
            <Text type="caption" className={css.Caption}>
              {previewUploadDescription}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsPreview;
