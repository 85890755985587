import cx from 'classnames';
import React from 'react';

import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './news-textarea.module.scss';

interface NewsTextareaProps {
  value: string;
  changeValue: (value: string) => void;
  maxValueChars?: number;
  label?: string;
  maxHeight?: string;
  className?: string;
}

const NewsTextarea: React.FC<NewsTextareaProps> = ({
  value,
  changeValue,
  maxValueChars,
  maxHeight,
  label,
  className,
}) => {
  const isTextareaCharsLimit = value.length === maxValueChars;

  const textareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentValue = e.currentTarget.value;

    if (maxValueChars && currentValue.length > maxValueChars) {
      changeValue(currentValue.slice(0, maxValueChars));
      return;
    } else {
      changeValue(currentValue);
    }
  };

  return (
    <div className={cx(className && className, css.Root)}>
      <Flex
        flexDirection="column"
        gap={GridGap.x1}
        className={cx(
          css.Container,
          isTextareaCharsLimit && css.CharsCountLimit
        )}
      >
        {label && <p className={css.Title}>{label}</p>}

        <textarea
          className={css.Textarea}
          value={value}
          onChange={textareaChange}
          style={{ maxHeight: maxHeight }}
        />

        {maxValueChars && (
          <p
            className={cx(
              css.ValueCharsCount,
              isTextareaCharsLimit && css.ValueCharsCountLimit
            )}
          >
            {value.length}/{maxValueChars}
          </p>
        )}
      </Flex>
    </div>
  );
};

export default NewsTextarea;
