import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface AddWalletAddressMutation {
  addWalletAddress:
    | {
        __typename: 'AddWalletAddress';
        address: string;
      }
    | GraphqlErrorType;
}

export interface AddWalletAddressMutationVars {
  address: string;
  sign: string;
}

const ADD_WALLET_ADDRESS = gql`
  mutation AddWalletAddress($address: String!, $sign: String!) {
    addWalletAddress(address: $address, sign: $sign) {
      __typename
      ... on AddWalletAddress {
        address
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default ADD_WALLET_ADDRESS;
