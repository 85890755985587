import { SortDirection } from 'ui/sorting-button/sorting-button.types';

export const getCorrectSortDirection = (direction: SortDirection) => {
  switch (direction) {
    case SortDirection.ASC: {
      return 'ASC';
    }
    case SortDirection.DESC: {
      return 'DESC';
    }
    default: {
      return undefined;
    }
  }
};
