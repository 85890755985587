import React, { MutableRefObject } from 'react';

import { Card } from 'ui';
import PinIcon from 'ui/assets/icons/16/pin.svg';
import { Text } from 'ui/v2/typography/text';

import css from './published-news-preview.module.scss';
import { OnePublishedNewsDataType } from '../../types/one-published-news-data-type';
import NewsFooter from '../news-footer/news-footer';
import NewsPreview from '../news-preview';

interface PublishedNewsPreviewProps {
  triggerRef?: MutableRefObject<HTMLDivElement | null>;
  news: Omit<OnePublishedNewsDataType, 'blocks'>;
  editor: boolean;
  isPinned?: boolean;
  likeNews: () => void;
  showComments: () => void;
  openNews: () => void;
  deleteNews?: () => void;
  editNews?: () => void;
  addToBookmarks: () => void;
}

const PublishedNewsPreview: React.FC<PublishedNewsPreviewProps> = ({
  news,
  editor,
  isPinned,
  likeNews,
  openNews,
  deleteNews,
  editNews,
  showComments,
  addToBookmarks,
  triggerRef,
}) => {
  const {
    id,
    client,
    commentsCount,
    likesCount,
    isLiked,
    views,
    bookmarksInfo,
    previewUploadDescription,
    ...newsPreviewData
  } = news;

  const newsFooterData = {
    client,
    commentsCount,
    likesCount,
    isLiked,
    views,
    bookmarksInfo,
  };

  return (
    <Card className={css.Root} ref={triggerRef}>
      {isPinned && <PinIcon className={css.Pin} />}
      <div className={css.previewUploadDescription}>
        <NewsPreview
          newsPreviewData={newsPreviewData}
          editor={editor}
          openNews={openNews}
          deleteNews={deleteNews}
          editNews={editNews}
        />

        {previewUploadDescription && (
          <Text type="caption" className={css.Caption}>
            {previewUploadDescription}
          </Text>
        )}
      </div>

      <NewsFooter
        newsFooterData={newsFooterData}
        className={css.NewsFooter}
        likeNews={likeNews}
        showComments={showComments}
        addToBookmarks={addToBookmarks}
      />
    </Card>
  );
};

export default PublishedNewsPreview;
