import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import LOAD_CONTENT_WIDGET_DATA, {
  LoadContentWidgetDataMutation,
  LoadContentWidgetDataMutationVars,
} from '../graphql/load-content-widget-data';

const useLoadWidgetData = () => {
  const { t } = useTranslation(['news']);
  const [loadWidgetDataMutation] = useMutation<
    LoadContentWidgetDataMutation,
    LoadContentWidgetDataMutationVars
  >(LOAD_CONTENT_WIDGET_DATA);

  return async (url: string) => {
    try {
      const { data, errors } = await loadWidgetDataMutation({
        variables: { url },
      });

      const WidgetData = verifyApolloMutationResponse(data, errors);
      if (!WidgetData) {
        return {
          success: 0,
          url,
        };
      }

      if (data?.loadContentWidgetData.__typename === 'Error') {
        Toast.error(data.loadContentWidgetData.error);
        return {
          success: 0,
          url,
        };
      }

      if (data?.loadContentWidgetData.__typename === 'LoadContentWidgetData') {
        return {
          success: 1,
          html: data.loadContentWidgetData.html,
          serviceName: data.loadContentWidgetData.serviceName,
          url: data.loadContentWidgetData.url,
        };
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:link_load_error'));
      return {
        success: 0,
        url,
      };
    }
  };
};

export default useLoadWidgetData;
