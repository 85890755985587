import React, { ChangeEvent, Ref } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import { isTouchDevice } from 'application/utils/is-touch-device';
import ClientAvatar from 'ui/client-avatar';
import { Flex } from 'ui/flex';
import CrossIcon from 'ui/icons/cross';

import css from './upload-client-avatar.module.scss';
import UploadClientAvatarMobileMenu from '../upload-client-avatar-mobile-menu';

interface UploadClientAvatarProps {
  avatar: string | null;
  size: 'M' | 'L';
  inputRef: Ref<HTMLInputElement>;
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: (value: boolean) => void;
  modalOpen: () => void;
  deleteAvatar: () => void;
  onChangeFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

const UploadClientAvatar: React.FC<UploadClientAvatarProps> = ({
  avatar,
  size,
  inputRef,
  isMobileMenuOpen,
  setMobileMenuOpen,
  deleteAvatar,
  onChangeFile,
  modalOpen,
}) => {
  const touchDevice = isTouchDevice();

  const avatarOnClick = () => {
    touchDevice ? setMobileMenuOpen(true) : modalOpen();
  };

  return (
    <div className={css.Root}>
      <Flex style={{ columnGap: '4px' }}>
        <div className={css.AvatarContainer} onClick={avatarOnClick}>
          <ClientAvatar avatar={avatar} size={size} />
        </div>

        {avatar && !touchDevice && (
          <CrossIcon className={css.Cross} onClick={deleteAvatar} />
        )}

        {isMobileMenuOpen && (
          <UploadClientAvatarMobileMenu
            size={size}
            avatar={avatar}
            modalOpen={modalOpen}
            deleteAvatar={() => {
              deleteAvatar();
              setMobileMenuOpen(false);
            }}
            closeMobileMenu={() => isMobileMenuOpen && setMobileMenuOpen(false)}
          />
        )}
      </Flex>

      <input
        type="file"
        hidden={true}
        ref={inputRef}
        onChange={onChangeFile}
        accept="image/*"
      />
    </div>
  );
};

export default UploadClientAvatar;
