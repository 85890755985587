import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from 'ui/v2/typography/text';

import css from './news-preview-image.module.scss';

interface NewsPreviewImageProps {
  imageUrl: string;
  addBackground: boolean;
  maxHeight: string;
  caption?: string;
}

const NewsPreviewImage: React.FC<NewsPreviewImageProps> = ({
  imageUrl,
  addBackground,
  maxHeight,
  caption,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={css.Root} style={{ maxHeight: `${maxHeight}px` }}>
      {addBackground && (
        <img
          src={imageUrl}
          alt={t('common:alt:preview_image')}
          className={css.ImageBackground}
        />
      )}

      <div className={css.ImagePreview}>
        <img
          className={cx(addBackground ? css.ImageWithBackground : css.Image)}
          src={imageUrl}
          alt={t('common:alt:preview_image')}
        />
        {caption && (
          <Text type="caption" className={css.Caption}>
            {caption}
          </Text>
        )}
      </div>
    </div>
  );
};

export default NewsPreviewImage;
