import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'ui';
import { Flex } from 'ui/flex';

import UnpublishedNewsPreviewList from './unpublished-news-preview-list';
import css from './unpublished-news-preview-list.module.scss';
import { UnpublishedNewsStatusType } from '../../graphql/get-my-unpublished-news-list';
import useGetUnpublishedNews from '../../hooks/use-get-unpublished-news';
import CreateNewsModal from '../create-news-modal';

const UnpublishedNewsPreviewListContainer = () => {
  const { t } = useTranslation(['news']);
  const router = useRouter();
  const { data, refetch, unpublishedNewsId, setUnpublishedNewsId } =
    useGetUnpublishedNews();

  const openUnpublishedNews = (status: string, id: string) => {
    const newsStatus = status as keyof typeof UnpublishedNewsStatusType;

    if (newsStatus === UnpublishedNewsStatusType.MODERATING) {
      router.push(`/unpublished-news/${id}`);
    } else {
      setUnpublishedNewsId(id);
    }
  };

  const closeNewsModal = () => {
    setUnpublishedNewsId(null);
    refetch();
  };

  if (
    data?.getMyUnpublishedNewsList.__typename !== 'GetMyUnpublishedNewsList'
  ) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  if (data.getMyUnpublishedNewsList.news.length === 0) {
    return <p className={css.Title}>{t('news:labels:no_drafts')}</p>;
  }

  return (
    <>
      <UnpublishedNewsPreviewList
        unpublishedNewsList={data.getMyUnpublishedNewsList.news}
        openUnpublishedNews={openUnpublishedNews}
        deleteUnpublishedNews={refetch}
      />

      {unpublishedNewsId !== null && (
        <CreateNewsModal
          closeModal={closeNewsModal}
          newsId={unpublishedNewsId}
        />
      )}
    </>
  );
};

export default UnpublishedNewsPreviewListContainer;
