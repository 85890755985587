import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Toast } from 'ui';

import GET_MY_WALLET_ADDRESSES, {
  GetMyWalletAddressesQuery,
  GetMyWalletAddressesQueryVars,
} from '../graphql/get-my-wallet-addresses';
import { type WalletAddress } from '../wallets-types';

const useGetMyWalletAddresses = () => {
  const [addresses, setAddresses] = useState<WalletAddress[] | null>(null);

  const { data, error, loading } = useQuery<
    GetMyWalletAddressesQuery,
    GetMyWalletAddressesQueryVars
  >(GET_MY_WALLET_ADDRESSES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data?.getMyWalletAddresses.__typename === 'Error') {
      Toast.error(data.getMyWalletAddresses.error);
    }

    if (data?.getMyWalletAddresses.__typename === 'GetMyWalletAddresses') {
      setAddresses(
        data.getMyWalletAddresses.addresses.map((item) => ({
          id: item.id,
          wallet: item.wallet,
          address: item.address,
        }))
      );
    }
  }, [data]);

  return { loading, addresses };
};

export default useGetMyWalletAddresses;
