import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'ui';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import Pagination from 'ui/pagination';

import css from './moderated-news-list.module.scss';
import { OneModeratedNewsDataType } from '../../graphql/get-moderation-news-list';
import {
  ModeratedNewsListFiltersType,
  updateFiltersType,
} from '../../hooks/use-set-moderated-news-list-filters';
import ModeratedNewsListFilters from '../moderated-news-list-filters';
import ModeratedNewsListItem from '../moderated-news-list-item';

export const MODERATED_NEWS_LIST_TABLE_GRID =
  '78px 1fr 128px 100px 130px 110px 24px';

const PER_PAGE_LIMIT = 10;

interface ModeratedNewsListProps {
  newsList: OneModeratedNewsDataType[];
  filters: ModeratedNewsListFiltersType;
  updateFilters: updateFiltersType;
  totalCount: number;
  resetAllFilters: () => void;
  setPage: (number: number) => void;
}

const ModeratedNewsList: React.FC<ModeratedNewsListProps> = ({
  newsList,
  filters,
  updateFilters,
  resetAllFilters,
  totalCount,
  setPage,
}) => {
  const { t } = useTranslation(['news', 'period']);

  return (
    <>
      <ModeratedNewsListFilters
        filters={filters}
        updateFilters={updateFilters}
        resetAllFilters={resetAllFilters}
      />

      {newsList.length === 0 ? (
        <p className={css.NoNews}>{t('news:labels:no_moderated_news')}</p>
      ) : (
        <>
          <div className={css.Root}>
            <div className={css.Wrapper}>
              <Card>
                <Grid
                  className={css.Header}
                  gridTemplateColumns={MODERATED_NEWS_LIST_TABLE_GRID}
                  gap={GridGap.x1}
                >
                  <div>{t('period:date')}</div>
                  <div>{t('news:labels:title')}</div>
                  <div>{t('news:labels:sport')}</div>
                  <div>{t('news:labels:category')}</div>
                  <div>{t('news:labels:author')}</div>
                  <div>{t('news:labels:status')}</div>
                </Grid>

                {newsList.map((news) => (
                  <ModeratedNewsListItem news={news} key={news.id} />
                ))}
              </Card>
            </div>
          </div>

          <div className={css.Pagination}>
            <Pagination
              setPage={setPage}
              totalItems={totalCount}
              page={Number(filters.pageNumber)}
              perPageLimit={PER_PAGE_LIMIT}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ModeratedNewsList;
