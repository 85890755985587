import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';

import { verifyApolloQueryResponse } from 'application/graphql/utils';
import { Loader, Toast } from 'ui';
import { Flex } from 'ui/flex';

import ModeratedNewsList from './moderated-news-list';
import GET_MODERATED_NEWS_LIST, {
  GetModeratedNewsListQuery,
  GetModeratedNewsListVars,
} from '../../graphql/get-moderation-news-list';
import useSetModeratedNewsListFilters from '../../hooks/use-set-moderated-news-list-filters';

const ModeratedNewsListContainer = () => {
  const { filters, updateFilters, resetAllFilters } =
    useSetModeratedNewsListFilters();
  const { status, sports, sort, pageNumber, categories } = filters;
  const { data, error, loading, refetch } = useQuery<
    GetModeratedNewsListQuery,
    GetModeratedNewsListVars
  >(GET_MODERATED_NEWS_LIST, {
    variables: {
      sort,
      pageNumber,
      filter: {
        categories,
        sports,
        status,
      },
    },
  });

  useEffect(() => {
    if (error) {
      verifyApolloQueryResponse(data, error);
      return;
    }

    if (data?.getModeratedNewsList.__typename === 'Error') {
      Toast.error(data.getModeratedNewsList.error);
    }
  }, [data, error]);

  useEffect(() => {
    refetch({
      sort,
      pageNumber,
      filter: {
        categories,
        sports,
        status,
      },
    });
  }, [filters, refetch]);

  if (loading) {
    return (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    );
  }

  if (data?.getModeratedNewsList.__typename !== 'GetModeratedNewsList') {
    return null;
  }

  return (
    <ModeratedNewsList
      newsList={data.getModeratedNewsList.news}
      filters={filters}
      updateFilters={updateFilters}
      resetAllFilters={resetAllFilters}
      totalCount={data?.getModeratedNewsList.totalCount}
      setPage={(page) => updateFilters('pageNumber', String(page))}
    />
  );
};

export default ModeratedNewsListContainer;
