import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface LoadContentWidgetDataMutation {
  loadContentWidgetData:
    | {
        __typename: 'LoadContentWidgetData';
        html?: string;
        serviceName?: string;
        url?: string;
      }
    | GraphqlErrorType;
}

export interface LoadContentWidgetDataMutationVars {
  url: string;
}

const LOAD_CONTENT_WIDGET_DATA = gql`
  mutation LoadContentWidgetData($url: String!) {
    loadContentWidgetData(url: $url) {
      ... on LoadContentWidgetData {
        html
        serviceName
        url
      }
      ... on Error {
        error
      }
    }
  }
`;

export default LOAD_CONTENT_WIDGET_DATA;
