import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import EventsListTableContent from './events-list-table-content';
import EventsListTableHeader from './events-list-table-header';
import { StatsEvent } from '../../graphql/get-user-statistic-live-orders';

interface Props {
  ordersList: StatsEvent[];
  isLoading?: boolean;
  sort: SortDirection;
  setSort: (direction: SortDirection) => void;
}

const EventsList = ({ ordersList, ...rest }: Props) => {
  const gridTemplateColumns =
    '110px 50px minmax(180px, 1fr) minmax(140px, 1fr) 85px 100px 100px 100px 100px 60px 60px 60px 110px';

  const gridTemplateColumnsTablet =
    '90px 50px minmax(180px, 1fr) minmax(140px, 1fr) 85px 100px 100px 100px 100px 60px 60px 60px 90px';

  const gridTemplateColumnsMobile =
    '70px 50px minmax(180px, 1fr) minmax(140px, 1fr) 85px 100px 100px 100px 100px 60px 60px 60px 80px';

  return (
    <VerticalScrollWrapper>
      <EventsListTableHeader
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
        {...rest}
      />
      <EventsListTableContent
        ordersList={ordersList}
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
      />
    </VerticalScrollWrapper>
  );
};

export default EventsList;
