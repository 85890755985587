import {
  BandagedHandIcon,
  BellIcon,
  GlovesTouchingIcon,
  PunchBagIcon,
  RobeIcon,
  RoundIcon,
  TicketIcon,
  TitleBeltIcon,
  WorldTitleIcon,
} from 'ui/icons';

export const challengesMock = [
  {
    text: 'Самый крупный выигрыш в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: TicketIcon,
  },
  {
    text: 'Проголосовать фантиками в 10 боях',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: PunchBagIcon,
    active: true,
  },
  {
    text: 'Проголосовать в 20 боях',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: GlovesTouchingIcon,
    active: true,
  },
  {
    text: 'Самый крупный выигрыш в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: BandagedHandIcon,
    active: true,
  },
  {
    text: 'Сделать больше всех ставок в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: TitleBeltIcon,
  },
  {
    text: 'Сделать больше всех ставок в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: WorldTitleIcon,
    active: true,
  },
  {
    text: 'Сделать больше всех ставок в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: BellIcon,
  },
  {
    text: 'Сделать больше всех ставок в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: RoundIcon,
  },
  {
    text: 'Сделать больше всех ставок в 2021 году',
    desc: 'Все знают Дэйну Уайта как отличного управленца — именно он превратил UFC из компании-банкрота в одного из топов мира спорта.',
    img: RobeIcon,
    active: true,
  },
];
