import React, { ReactElement } from 'react';

import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';

import RadioGroupContext from './radio-group.context';

interface RadioGroupProps<V> {
  children: ReactElement[] | ReactElement;
  value?: V;
  onChange: (event: V) => void;
}

export const RadioGroup: React.FC<RadioGroupProps<any>> = ({
  children,
  value,
  onChange,
}) => {
  const handleChange = (value: number) => {
    onChange(value);
  };

  return (
    <RadioGroupContext.Provider value={{ value, onChange: handleChange }}>
      <Grid gap={GridGap.x2}>{children}</Grid>
    </RadioGroupContext.Provider>
  );
};
