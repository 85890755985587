import React from 'react';
import { useTranslation } from 'react-i18next';

import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { Text } from 'ui/v2/typography/text';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import OrderListTableContent from './order-list-table-content';
import OrderListTableHeader from './order-list-table-header';
import { StatsOrder } from '../../graphql/get-user-statistic-live-orders';

interface Props {
  ordersList: StatsOrder[];
  onDeleteOrder: (id: string) => void;
  isLoading?: boolean;
  sort: SortDirection;
  setSort: (arg: SortDirection) => void;
}

const LiveOrdersList = ({
  ordersList,
  onDeleteOrder,
  isLoading,
  sort,
  setSort,
}: Props) => {
  const { t } = useTranslation(['common']);

  const gridTemplateColumns =
    '90px minmax(160px, 1fr) minmax(160px, 1fr) 80px 80px 100px 100px 100px 100px 100px 184px';

  const gridTemplateColumnsTablet =
    '80px minmax(160px, 1fr) minmax(160px, 1fr) 80px 80px 100px 100px 100px 100px 100px 132px';

  const gridTemplateColumnsMobile =
    '70px minmax(160px, 1fr) minmax(160px, 1fr) 80px 80px 100px 100px 100px 100px 100px 132px';

  if (ordersList.length === 0) {
    return (
      <Text type="paragraph" textAlign="center" style={{ padding: 20 }}>
        {t('common:labels:no_data')}
      </Text>
    );
  }

  return (
    <VerticalScrollWrapper>
      <OrderListTableHeader
        sort={sort}
        setSort={setSort}
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
      />
      <OrderListTableContent
        ordersList={ordersList}
        onDeleteOrder={onDeleteOrder}
        isLoading={isLoading}
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
      />
    </VerticalScrollWrapper>
  );
};

export default LiveOrdersList;
