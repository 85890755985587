export interface Debounced {
  (...args: unknown[]): void;

  cancel: () => void;
}

export interface Debounce {
  (callback: Function, delay: number): Debounced;
}

export const debounce: Debounce = (callback, delay) => {
  let timeoutId: unknown;

  const cancel = () => clearTimeout(timeoutId as number);

  const debounced: Debounced = (...args) => {
    timeoutId && cancel();

    timeoutId = setTimeout(() => {
      callback?.apply(null, args);
    }, delay);
  };

  debounced.cancel = cancel;

  return debounced;
};
