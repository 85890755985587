import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';

import ADD_WALLET_ADDRESS, {
  AddWalletAddressMutation,
  AddWalletAddressMutationVars,
} from '../graphql/add-wallet-address';
import GET_MY_WALLET_ADDRESSES from '../graphql/get-my-wallet-addresses';

const useAddWalletAddressClient = () => {
  const { t } = useTranslation(['common', 'wallets']);

  const [addWalletAddress] = useMutation<
    AddWalletAddressMutation,
    AddWalletAddressMutationVars
  >(ADD_WALLET_ADDRESS);

  const addWalletAddressCallback = useCallback(
    async (address: string, sign: string) => {
      const { errors, data } = await addWalletAddress({
        variables: {
          address,
          sign,
        },
        refetchQueries: [GET_MY_WALLET_ADDRESSES],
      });

      if (errors) {
        Toast.error(errors.map((item) => item.name).join(', '));
        return;
      }

      if (!data) {
        Toast.error(t('common:errors:undefined_error'));
        return;
      }

      if (data.addWalletAddress.__typename === 'Error') {
        Toast.error(data.addWalletAddress.error);
        return;
      }

      Toast.info(
        t('wallets:success_add_account', {
          address: data.addWalletAddress.address,
        })
      );
    },
    [addWalletAddress, t]
  );

  return addWalletAddressCallback;
};

export default useAddWalletAddressClient;
