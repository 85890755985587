import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import EventsListTableContent from './events-list-table-content';
import EventsListTableHeader from './events-list-table-header';
import { PariStatsEvent } from '../../graphql/get-user-statistic-pari-events';

interface Props {
  pariList: PariStatsEvent[];
  sort: SortDirection;
  setSort: (direction: SortDirection) => void;
}

const EventsList = ({ pariList, ...rest }: Props) => {
  const gridTemplateColumns =
    '110px minmax(180px, 1fr) minmax(120px, 1fr) 50px 100px 100px 164px';
  const gridTemplateColumnsTablet =
    '90px minmax(180px, 1fr) minmax(120px, 1fr) 50px 100px 90px 132px';

  const gridTemplateColumnsMobile =
    '70px minmax(180px, 1fr) minmax(120px, 1fr) 50px 100px 70px 92px';

  return (
    <VerticalScrollWrapper>
      <EventsListTableHeader
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
        {...rest}
      />
      <EventsListTableContent
        pariList={pariList}
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateColumnsTablet={gridTemplateColumnsTablet}
        gridTemplateColumnsMobile={gridTemplateColumnsMobile}
      />
    </VerticalScrollWrapper>
  );
};

export default EventsList;
