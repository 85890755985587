import { Loader } from 'ui';
import Card from 'ui/card';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import Pagination from 'ui/pagination';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import Switch from 'ui/switch';
import Tabs from 'ui/tabs';

import LiveOrdersList from './components/live-orders-list/live-orders-list';
import { LiveOrdersTabs, useGetLiveOrders } from './hooks/use-get-live-orders';
import { useManagementOrders } from './hooks/use-management-orders';
import css from './user-statistic-live-orders.module.scss';

const LiveOrdersListConnected = () => {
  const {
    data,
    loading,
    orderType,
    setOrderType,
    switchItems,
    tabs,
    sort,
    setSort,
    pagination,
  } = useGetLiveOrders();

  const { onRemoveBid, isBidLoad, onRemoveMarketOrder, isMarketOrderLoad } =
    useManagementOrders({
      isActive: tabs.current === LiveOrdersTabs.ACTIVE,
      sortBy: 'date',
      sortDirection: sort,
      successCallback: () => pagination.setPage(0),
    });

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );
  }

  if (data?.getStatsLiveOrders.__typename !== 'GetStatsLiveOrders') {
    return null;
  }

  return (
    <Card>
      <ResponsiveGrid
        gap={GridGap.x3}
        mobile={{ gap: GridGap.x2 }}
        className={css.Wrapper}
      >
        <div className={css.Card}>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Tabs
              value={tabs.current}
              items={tabs.items}
              onSelect={tabs.setTab}
            />
            <Switch
              value={orderType}
              items={switchItems}
              onChange={setOrderType}
            />
          </Flex>
        </div>

        <LiveOrdersList
          sort={sort}
          setSort={setSort}
          ordersList={data.getStatsLiveOrders.orders}
          isLoading={isBidLoad || isMarketOrderLoad}
          onDeleteOrder={
            orderType === 'market' ? onRemoveMarketOrder : onRemoveBid
          }
        />
        {+data.getStatsLiveOrders.ordersCount > 15 && (
          <Flex justifyContent="center" className={css.Pagination}>
            <Pagination
              page={pagination.page}
              perPageLimit={15}
              setPage={pagination.setPage}
              totalItems={+data.getStatsLiveOrders.ordersCount}
            />
          </Flex>
        )}
      </ResponsiveGrid>
    </Card>
  );
};

export default LiveOrdersListConnected;
