import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { formatDatetimeToFull } from 'application/utils/date';
import { roundMoney } from 'application/utils/money';
import { StickyColumn } from 'features/sticky-column/sticky-column';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';

import { PariStatsEvent } from '../../graphql/get-user-statistic-pari-events';
import css from '../../user-statistic-events.module.scss';
import { PnlPrint } from '../pnl-print/PnlPrint';

interface Props {
  pariList: PariStatsEvent[];
  gridTemplateColumns: string;
  gridTemplateColumnsTablet: string;
  gridTemplateColumnsMobile: string;
}

const EventsListTableContent = ({
  pariList,
  gridTemplateColumns,
  gridTemplateColumnsTablet,
  gridTemplateColumnsMobile,
}: Props) => {
  const { t } = useTranslation(['fights']);

  return (
    <>
      {pariList.map((el, index) => {
        const pnl = roundMoney(
          el.outcomes.reduce((acc, outcome) => acc + outcome.pnl, 0)
        );

        return (
          <div key={el.id}>
            <ResponsiveGrid
              alignItems="center"
              gap={GridGap.x2}
              gridTemplateColumns={gridTemplateColumns}
              mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
              tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
              gridAutoRows="minmax(74px, 1fr)"
              key={'event_' + el.id}
              className={cx(css.Table, css.Border_top, {
                [css.Gray_back]: index % 2 === 0,
              })}
            >
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
                className={css.Wrap}
              >
                {formatDatetimeToFull(new Date(el.startDate))}
              </Text>
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
              >
                {el.name}
              </Text>
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <StickyColumn>
                <PnlPrint value={pnl} />
              </StickyColumn>
            </ResponsiveGrid>
            {el.outcomes.map((el) => {
              return (
                <ResponsiveGrid
                  alignItems="center"
                  gap={GridGap.x2}
                  gridTemplateColumns={gridTemplateColumns}
                  mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
                  tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
                  gridAutoRows="40px"
                  key={'event_' + el.id}
                  className={cx(css.Table, css.Border_top, {
                    [css.Gray_back]: index % 2 === 0,
                  })}
                >
                  <Text type="paragraph" />
                  <Text type="paragraph" />
                  <Text
                    type="paragraph"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.name}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.cof}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.sum}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.expiration
                      ? el.win
                        ? t('fights:win')
                        : t('fights:loss')
                      : '-'}
                  </Text>
                  <StickyColumn>
                    <PnlPrint value={el.pnl} />
                  </StickyColumn>
                </ResponsiveGrid>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default EventsListTableContent;
