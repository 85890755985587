import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import UPLOAD_CONTENT_IMAGE, {
  UploadContentImageMutation,
  UploadContentImageMutationVars,
} from '../graphql/upload-content-image';

export type UploadImageResponseSuccess = {
  success: 1;
  file: {
    url: string;
  };
};

type UploadImageResponseError = {
  success: 0;
  message: string;
};

type UploadImageResponse =
  | UploadImageResponseSuccess
  | UploadImageResponseError;

const useUploadImage = () => {
  const { t } = useTranslation(['news']);
  const [uploadImageMutation] = useMutation<
    UploadContentImageMutation,
    UploadContentImageMutationVars
  >(UPLOAD_CONTENT_IMAGE);

  const imageUploadErrorResponse: UploadImageResponseError = {
    success: 0,
    message: t('news:errors:upload_image_error'),
  };

  return async (file: File): Promise<UploadImageResponse> => {
    try {
      const { data, errors } = await uploadImageMutation({
        variables: { file },
      });

      const isImageUploadSuccess = verifyApolloMutationResponse(data, errors);
      if (!isImageUploadSuccess) {
        return imageUploadErrorResponse;
      }

      if (data?.uploadContentImage.__typename === 'Error') {
        Toast.error(data.uploadContentImage.error);
        return imageUploadErrorResponse;
      }

      if (data?.uploadContentImage.__typename === 'UploadContentImage') {
        return {
          success: 1,
          file: { url: data.uploadContentImage.fileUrl },
        };
      }

      return imageUploadErrorResponse;
    } catch (e: unknown) {
      Toast.error(t('news:errors:upload_image_error'));
      return imageUploadErrorResponse;
    }
  };
};

export default useUploadImage;
