import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { WalletTypes } from '../wallets-types';

export interface GetMyWalletAddressesQuery {
  getMyWalletAddresses:
    | {
        __typename: 'GetMyWalletAddresses';
        addresses: {
          id: string;
          wallet: WalletTypes;
          address: string;
        }[];
      }
    | GraphqlErrorType;
}

export interface GetMyWalletAddressesQueryVars {}

const GET_MY_WALLET_ADDRESSES = gql`
  query GetMyWalletAddresses {
    getMyWalletAddresses {
      __typename
      ... on GetMyWalletAddresses {
        addresses {
          id
          wallet
          address
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default GET_MY_WALLET_ADDRESSES;
