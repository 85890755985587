import React, { ReactNode } from 'react';

import { Grid, GridProps } from 'ui/grid';

import { useMediaQuery } from '../../hooks/use-media-query.hook';

interface MediaQueryContainerProps extends GridProps {
  children: ReactNode;
  mobile?: Omit<GridProps, 'children'>;
  tablet?: Omit<GridProps, 'children'>;
}

export const ResponsiveGrid: React.FC<MediaQueryContainerProps> = ({
  children,
  mobile,
  tablet,
  ...gridArguments
}) => {
  const isMobile = useMediaQuery('(max-width: 430px)');
  const isTablet = useMediaQuery('(max-width: 834px)');

  let gridProps = {
    ...gridArguments,
  };

  if (isTablet) {
    gridProps = {
      ...gridArguments,
      ...tablet,
    };
  }

  if (isMobile) {
    gridProps = {
      ...gridArguments,
      ...mobile,
    };
  }

  return <Grid {...gridProps}>{children}</Grid>;
};
