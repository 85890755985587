import React, { useEffect, useState } from 'react';

import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import css from './sorting-button-light.module.scss';

interface SortingButtonLightProps {
  children: React.ReactNode;
  onChange?: (state: SortDirection) => void;
  align?: 'right' | 'left';
  activeDirection?: SortDirection;
  active?: boolean;
  stateless?: boolean;
  directions?: SortDirection[];
  className?: string;
}

export const SortingButtonLight: React.FC<SortingButtonLightProps> = ({
  children,
  onChange,
  align = 'left',
  active,
  activeDirection = SortDirection.ASC,
  className,
}) => {
  const [direction, setDirection] = useState(SortDirection.NONE);

  const classes = [css.sorting_button, className];

  if (direction !== SortDirection.NONE) {
    classes.push(css.active);
  }

  const onSort = () => {
    setDirection(activeDirection);
    onChange?.(activeDirection);
  };

  useEffect(() => {
    if (!active) {
      setDirection(SortDirection.NONE);
    } else {
      setDirection(activeDirection);
    }
  }, [active]);

  return (
    <span className={classes.join(' ')} onClick={onSort}>
      <span>{children}</span>
    </span>
  );
};
