import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { EventSportType } from './get-event-sports-list';
import { CategoryType } from './get-news-categories';

export enum UnpublishedNewsStatusType {
  DRAFT = 'DRAFT',
  MODERATING = 'MODERATING',
  REJECTED = 'REJECTED',
}

export interface News {
  id: string;
  sport: EventSportType;
  category: CategoryType;
  title: string;
  previewDescription: string;
  previewUploadDescription: string;
  previewImage: string;
  previewVideo: string;
  status: string;
  date: string;
}

export interface GetMyUnpublishedNewsListQuery {
  getMyUnpublishedNewsList:
    | {
        __typename: 'GetMyUnpublishedNewsList';
        news: News[];
      }
    | GraphqlErrorType;
}

const GET_MY_UNPUBLISHED_NEWS_LIST = gql`
  query GetMyUnpublishedNewsList {
    getMyUnpublishedNewsList {
      ... on GetMyUnpublishedNewsList {
        news {
          id
          sport {
            id
            name
            icon
          }
          category {
            id
            name
          }
          title
          previewDescription
          previewUploadDescription
          previewImage
          previewVideo
          status
          date
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MY_UNPUBLISHED_NEWS_LIST;
