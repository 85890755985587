import { Text } from 'ui/v2/typography/text';

type Props = {
  value: number;
};

export const PnlPrint = ({ value }: Props) => {
  return (
    <Text
      type="body"
      color={value < 0 ? 'negative-color' : 'positive-color'}
      textAlign="center"
      mobile={{ fontSize: '12px' }}
      tablet={{ fontSize: '14px' }}
      strong
    >
      {value}
    </Text>
  );
};
