import React, { useEffect } from 'react';

import { removeTypename } from 'application/utils/remove-typename';

import NewsRejectedReasonList from './news-rejected-reason-list';
import useGetNewsRejectedReasonsList from '../../hooks/use-get-news-rejected-reasons-list';
import { RejectedReason } from '../moderated-news-modal';

export const REASON_OTHER_ID = '7';

interface NewsRejectedReasonListContainerProps {
  setRejectedReason: (reasonItem: RejectedReason) => void;
}

const NewsRejectedReasonListContainer: React.FC<
  NewsRejectedReasonListContainerProps
> = ({ setRejectedReason }) => {
  const reasonsList = useGetNewsRejectedReasonsList();
  const [reasonId, setReasonId] = React.useState('');
  const [otherReasonText, setOtherReasonText] = React.useState('');

  useEffect(() => {
    if (!reasonsList) return;

    const reasonItem = reasonsList.find((reason) => reason.id === reasonId);
    if (!reasonItem) return;

    const rejectedReason = removeTypename(reasonItem);

    setRejectedReason(
      rejectedReason.id === REASON_OTHER_ID
        ? { ...rejectedReason, reason: otherReasonText }
        : rejectedReason
    );

    return () => {
      setRejectedReason(null);
    };
  }, [reasonId, reasonsList]);

  useEffect(() => {
    if (reasonId === REASON_OTHER_ID) {
      setRejectedReason({ id: reasonId, reason: otherReasonText });
    }
  }, [otherReasonText]);

  if (!reasonsList) {
    return null;
  }

  return (
    <NewsRejectedReasonList
      reasonsList={reasonsList}
      reasonId={reasonId}
      otherReasonText={otherReasonText}
      setOtherReasonText={setOtherReasonText}
      setReasonId={setReasonId}
    />
  );
};

export default NewsRejectedReasonListContainer;
