import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_BOOKMARKS_NEWS_LIST, {
  BookmarksNews,
} from '../graphql/get-bookmarks-news-list';
import GET_NEWS_LIST, { GetNewsListQueryVars } from '../graphql/get-news-list';
import GET_ONE_PUBLISHED_NEWS_FOOTER_DATA from '../graphql/get-one-published-news-footer-data';
import LIKE_NEWS, {
  LikeNewsMutation,
  LikeNewsMutationVars,
} from '../graphql/like-news';
import { ComponentOrigins } from '../types/component-origins';
import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

const useLikeNews = (
  componentOrigin: ComponentOrigins,
  newsListVariables?: GetNewsListQueryVars
) => {
  const { t } = useTranslation(['news']);
  const [likeNewsMutation] = useMutation<
    LikeNewsMutation,
    LikeNewsMutationVars
  >(LIKE_NEWS, {
    refetchQueries: (mutationResult) => {
      if (mutationResult?.data?.likeNews.__typename !== 'LikeNews') {
        return [];
      }

      const { newsId } = mutationResult?.data?.likeNews?.like;
      if (componentOrigin === ComponentOrigins.ONE_NEWS && newsId) {
        return [
          {
            query: GET_ONE_PUBLISHED_NEWS_FOOTER_DATA,
            variables: { id: newsId },
          },
        ];
      }

      return [];
    },
    update(cache, { data }) {
      if (data?.likeNews.__typename !== 'LikeNews') {
        return;
      }

      const { newsId, isLiked, likesCount } = data?.likeNews.like;
      if (!newsId) return;

      const bookmarksNewsList: {
        getBookmarksNewsList: { news: BookmarksNews[] };
      } | null = cache.readQuery({
        query: GET_BOOKMARKS_NEWS_LIST,
      });
      const bookmarksNewsToUpdate =
        bookmarksNewsList?.getBookmarksNewsList.news.find(
          (news) => news.id === newsId
        );

      if (bookmarksNewsList && bookmarksNewsToUpdate) {
        cache.modify({
          id: cache.identify(bookmarksNewsToUpdate),
          fields: {
            isLiked() {
              return isLiked;
            },
            likesCount() {
              return likesCount;
            },
          },
        });
      }

      if (!newsListVariables) return;

      const newsList: {
        getNewsList: { news: OnePublishedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
      });

      if (!newsList) return;

      const newsToUpdate = newsList.getNewsList.news.find(
        (news) => news.id === newsId
      );

      if (!newsToUpdate) return;

      cache.modify({
        id: cache.identify(newsToUpdate),
        fields: {
          isLiked() {
            return isLiked;
          },
          likesCount() {
            return likesCount;
          },
        },
      });
    },
  });

  return async (id: string, isLiked: boolean, likesCount: number) => {
    try {
      const { data, errors } = await likeNewsMutation({
        variables: { id },
        optimisticResponse: {
          likeNews: {
            __typename: 'LikeNews',
            like: {
              newsId: id,
              isLiked,
              likesCount,
            },
          },
        },
      });

      const isLikeNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isLikeNewsSuccess) return;

      if (data?.likeNews.__typename === 'Error') {
        Toast.error(data.likeNews.error);
        return;
      }

      if (data?.likeNews.__typename === 'LikeNews') {
        return {
          isLiked: data.likeNews.like.isLiked,
          likesCount: data.likeNews.like.likesCount,
          newsId: data.likeNews.like.newsId,
        };
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:like_error'));
    }
  };
};

export default useLikeNews;
