import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { errorNotification } from 'application/notification';
import { isGraphqlError } from 'features/main/components/graphql-error-guard/graphql-error-guard';

import GET_MY_CLIENT_TRANSACTIONS, {
  GetMyClientTransactionsQuery,
  GetMyClientTransactionsQueryVars,
} from '../graphql/get-my-client-transactions';

const PAGE_LIMIT = 50;

enum TransactionsTabs {
  TRADING = 'trading',
  BALANCE = 'balance',
}

export const useGetTransactionsHistory = (balanceType: 'vusd' | 'mvusd') => {
  const { t } = useTranslation(['bets']);

  const [tab, setTab] = useState<TransactionsTabs>(TransactionsTabs.TRADING);

  const tabs = [
    { value: TransactionsTabs.TRADING, label: t('bets:trade') },
    { value: TransactionsTabs.BALANCE, label: t('bets:balance') },
  ];

  const { data, error, loading, fetchMore } = useQuery<
    GetMyClientTransactionsQuery,
    GetMyClientTransactionsQueryVars
  >(GET_MY_CLIENT_TRANSACTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      wallet: balanceType,
      transactionsType: tab,
      nav: { limit: PAGE_LIMIT, lastId: 0 },
    },
  });

  const onGetNextPage = () => {
    if (data?.getMyClientTransactions.__typename !== 'GetMyClientTransactions')
      return;
    fetchMore({
      variables: {
        wallet: balanceType,
        transactionsType: tab,
        nav: {
          limit: PAGE_LIMIT,
          lastId:
            Number(data?.getMyClientTransactions.transactions.at(-1)?.id) ||
            undefined,
        },
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (isGraphqlError(previousResult.getMyClientTransactions))
          return previousResult;
        if (isGraphqlError(fetchMoreResult.getMyClientTransactions))
          return previousResult;

        fetchMoreResult.getMyClientTransactions.transactions = [
          ...previousResult.getMyClientTransactions.transactions,
          ...fetchMoreResult.getMyClientTransactions.transactions,
        ];
        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    if (error) {
      errorNotification(error.message);
    }

    if (data?.getMyClientTransactions.__typename === 'Error') {
      errorNotification(data.getMyClientTransactions.error);
    }
  }, [data, error]);

  return {
    data,
    loading,
    onGetNextPage,
    tabs: { items: tabs, current: tab, setTab },
  };
};
