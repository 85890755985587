import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface DeleteMyUnpublishedNewsMutation {
  deleteMyUnpublishedNews:
    | {
        __typename: 'DeleteMyUnpublishedNews';
        news: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface DeleteMyUnpublishedNewsMutationVars {
  id: string;
}

const DELETE_MY_UNPUBLISHED_NEWS = gql`
  mutation DeleteMyUnpublishedNews($id: ID!) {
    deleteMyUnpublishedNews(id: $id) {
      ... on DeleteMyUnpublishedNews {
        news {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default DELETE_MY_UNPUBLISHED_NEWS;
