import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { ReviewInfoType } from './get-moderation-news-list';

export interface CancelNewsReviewMutation {
  cancelNewsReview:
    | {
        __typename: 'CancelNewsReview';
        newsId: string;
        reviewInfo: ReviewInfoType;
      }
    | GraphqlErrorType;
}

export interface CancelNewsReviewMutationVars {
  id: string;
}

const CANCEL_NEWS_REVIEW = gql`
  mutation CancelNewsReview($id: ID!) {
    cancelNewsReview(id: $id) {
      ... on CancelNewsReview {
        newsId
        reviewInfo {
          id
          status
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default CANCEL_NEWS_REVIEW;
