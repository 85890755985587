import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface BookmarksInfoType {
  id: string;
  inBookmarks: boolean;
}

export interface UpdateMyFavoriteNewsMutation {
  updateBookmarksNews:
    | {
        __typename: 'UpdateBookmarksNews';
        news: {
          id: string;
          bookmarksInfo: BookmarksInfoType;
        };
      }
    | GraphqlErrorType;
}

export interface UpdateBookmarksNewsMutationVars {
  newsId: string;
}

const UPDATE_BOOKMARKS_NEWS = gql`
  mutation UpdateBookmarksNews($newsId: ID!) {
    updateBookmarksNews(newsId: $newsId) {
      ... on UpdateBookmarksNews {
        news {
          id
          bookmarksInfo {
            id
            inBookmarks
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPDATE_BOOKMARKS_NEWS;
