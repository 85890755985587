import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Editor, Modal } from 'ui';
import { EditorBlocksType } from 'ui/editor';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './edit-news-modal.module.scss';
import { LoadNewsWidgetLinkType } from '../../types/load-news-widget-link-type';
import { NewsFormDataType } from '../../types/news-form-data-type';
import { UploadType } from '../../types/upload-type';
import NewsCategorySelect from '../news-category-select';
import NewsPreviewUpload from '../news-preview-upload';
import NewsSportSelect from '../news-sport-select';
import NewsTextarea from '../news-textarea';

const MAX_TITLE_CHARS = 110;
const MAX_DESCRIPTION_CHARS = 180;

interface EditNewsModalProps {
  newsData: NewsFormDataType;
  uploadNewsImage: UploadType;
  uploadNewsVideo: UploadType;
  loadNewsWidgetLink: LoadNewsWidgetLinkType;
  children?: ReactNode;
  myNewsReview?: boolean;
  saveSportId: (categoryId: string) => void;
  saveCategoryId: (sportId: string) => void;
  saveNewsTitle: (title: string) => void;
  saveNewsPreviewUploadDescription: (previewUploadDescription: string) => void;
  saveNewsPreviewDescription: (previewDescription: string) => void;
  uploadPreviewImage: (img: File) => void;
  uploadPreviewVideo: (img: File) => void;
  saveEditorBlocks: (blocks: EditorBlocksType) => void;
  closeModal: () => void;
  setNewsReview?: () => void;
  previewUploadDescription: string;
}

const EditNewsModal: React.FC<EditNewsModalProps> = ({
  newsData,
  uploadNewsImage,
  uploadNewsVideo,
  loadNewsWidgetLink,
  saveSportId,
  saveCategoryId,
  saveNewsTitle,
  saveNewsPreviewDescription,
  uploadPreviewImage,
  uploadPreviewVideo,
  saveEditorBlocks,
  children,
  closeModal,
  setNewsReview,
  myNewsReview,
  saveNewsPreviewUploadDescription,
}) => {
  const { t } = useTranslation(['news']);
  const {
    blocks,
    sportId,
    title,
    previewDescription,
    previewImage,
    previewVideo,
    categoryId,
    previewUploadDescription,
  } = newsData;

  return (
    <Modal title="" isOpen={true} width={840} onClose={closeModal}>
      <div className={css.ModalHeader}>
        <NewsSportSelect sportId={sportId} sportIdChange={saveSportId} />

        <div className={css.Divider} />

        <NewsCategorySelect
          categoryId={categoryId}
          categoryIdChange={saveCategoryId}
        />

        {setNewsReview && myNewsReview !== undefined && (
          <div className={css.StartReview}>
            <Checkbox
              checked={myNewsReview}
              onCheck={setNewsReview}
              text={t('news:actions:start_review')}
            />
          </div>
        )}
      </div>

      <div className={css.ModalContentWrapper}>
        <Flex
          gap={GridGap.x2}
          className={css.ModalContent}
          flexDirection="column"
        >
          <div className={css.MainTitleWrapper}>
            <NewsTextarea
              value={title}
              changeValue={saveNewsTitle}
              maxValueChars={MAX_TITLE_CHARS}
              label={t('news:labels:create_news_title')}
              maxHeight="150px"
            />
          </div>

          <NewsTextarea
            value={previewDescription}
            changeValue={saveNewsPreviewDescription}
            maxValueChars={MAX_DESCRIPTION_CHARS}
            label={t('news:labels:create_news_subtitle')}
          />

          <NewsPreviewUpload
            descriptionValue={previewUploadDescription}
            saveDescription={saveNewsPreviewUploadDescription}
            previewImage={previewImage}
            previewVideo={previewVideo}
            uploadPreviewImage={uploadPreviewImage}
            uploadPreviewVideo={uploadPreviewVideo}
          />
        </Flex>

        <Editor
          initBlocks={blocks}
          blocksChange={saveEditorBlocks}
          uploadImage={uploadNewsImage}
          uploadVideo={uploadNewsVideo}
          loadWidgetLink={loadNewsWidgetLink}
        />
      </div>

      {children}
    </Modal>
  );
};

export default EditNewsModal;
