import React from 'react';

import css from './switch.module.scss';

type SwitchItem<T> = { value: T; label: string };

interface SwitchProps<T> {
  value: T;
  items: SwitchItem<T>[];
  disabled?: boolean;
  onChange: (value: T) => void;
}

type SwitchPropsFC = <T>(
  props: SwitchProps<T>
) => React.ReactElement<SwitchProps<T>>;

const Switch: SwitchPropsFC = ({ items, value, onChange }) => {
  return (
    <div className={css.Root}>
      {items.map((item, index) => (
        <div
          key={index}
          className={value === item.value ? css.ItemActive : css.Item}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </div>
      ))}
      <div
        className={css.Switch}
        style={value === items[0].value ? { left: 2 } : { right: 2 }}
      />
    </div>
  );
};

export default Switch;
