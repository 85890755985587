const roundMoneyString = (amount: string) => {
  const number = parseFloat(amount);

  return Math.floor(number * 100) / 100;
};

const roundMoney = (amount: number) => {
  return Math.floor(amount * 100) / 100;
};

const formatMoney = (amount: number, isShortThousand = false) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: isShortThousand && amount > 999 ? 0 : 2,
  });
  return formatter.format(amount);
};

export { roundMoney, roundMoneyString, formatMoney };
