import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './unpublished-news-status.module.scss';
import { UnpublishedNewsStatusType } from '../../graphql/get-my-unpublished-news-list';

interface UnpublishedNewsStatusProps {
  newsStatus: string;
}

const UnpublishedNewsStatus: React.FC<UnpublishedNewsStatusProps> = ({
  newsStatus,
}) => {
  const { t } = useTranslation(['news']);
  const status = newsStatus as keyof typeof UnpublishedNewsStatusType;

  const statusClassName = cx(
    css.Status,
    status === UnpublishedNewsStatusType.DRAFT && css.StatusDraft,
    status === UnpublishedNewsStatusType.MODERATING && css.StatusModerating,
    status === UnpublishedNewsStatusType.REJECTED && css.StatusRejected
  );

  return (
    <div className={statusClassName}>{t(`news:statuses:${newsStatus}`)}</div>
  );
};

export default UnpublishedNewsStatus;
