import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { verifyApolloQueryResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_ONE_MODERATED_NEWS, {
  GetOneModeratedNewsQuery,
  GetOneModeratedNewsQueryVars,
} from '../graphql/get-one-moderated-news';

const useGetOneModeratedNews = (id: string) => {
  const { data, error } = useQuery<
    GetOneModeratedNewsQuery,
    GetOneModeratedNewsQueryVars
  >(GET_ONE_MODERATED_NEWS, { variables: { id } });

  useEffect(() => {
    if (error) {
      verifyApolloQueryResponse(data, error);
      return;
    }
    if (data?.getOneModeratedNews.__typename === 'Error') {
      Toast.error(data.getOneModeratedNews.error);
    }
  }, [data, error]);

  return {
    data:
      data?.getOneModeratedNews.__typename === 'GetOneModeratedNews'
        ? data.getOneModeratedNews
        : null,
    error: error,
  };
};

export default useGetOneModeratedNews;
