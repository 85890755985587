import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type ClientCountsType = {
  draftNewsCount: number;
  bookmarksNewsCount: number;
  publishedNewsCount: number;
  reviewingNewsCount: number;
};

export interface GetClientCountsQuery {
  getClientCounts:
    | {
        __typename: 'GetClientCounts';
        counts: ClientCountsType;
      }
    | GraphqlErrorType;
}

const GET_CLIENT_COUNTS = gql`
  query GetClientCounts {
    getClientCounts {
      __typename
      ... on GetClientCounts {
        counts {
          draftNewsCount
          bookmarksNewsCount
          publishedNewsCount
          reviewingNewsCount
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_CLIENT_COUNTS;
