import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { NewsRejectedReason } from './get-news-rejected-reason-list';

export interface ModerateNewsPublicationMutation {
  moderateNewsPublication:
    | {
        __typename: 'ModerateNewsPublication';
        newsId: string;
        totalCount: number;
        published: boolean;
      }
    | GraphqlErrorType;
}

export interface ModerateNewsPublicationMutationVars {
  moderateNewsPublicationId: string;
  isPublished: boolean;
  reasonData?: NewsRejectedReason;
}

const MODERATE_NEWS_PUBLICATION = gql`
  mutation ModerateNewsPublication(
    $moderateNewsPublicationId: ID!
    $isPublished: Boolean!
    $reasonData: ReasonData
  ) {
    moderateNewsPublication(
      id: $moderateNewsPublicationId
      isPublished: $isPublished
      reasonData: $reasonData
    ) {
      ... on ModerateNewsPublication {
        newsId
        published
        totalCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default MODERATE_NEWS_PUBLICATION;
