import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ModalFooter } from 'ui';

import css from './create-news-modal-footer.module.scss';
import { NewsSaveStatusType } from '../../types/news-save-status-type';

interface CreateNewsModalFooterProps {
  openNewsPreview: () => void;
  publishNews: () => void;
  buttonDisabled: boolean;
  newsSaveStatus: NewsSaveStatusType;
}

const CreateNewsModalFooter: React.FC<CreateNewsModalFooterProps> = ({
  openNewsPreview,
  publishNews,
  buttonDisabled,
  newsSaveStatus,
}) => {
  const { t } = useTranslation(['news']);

  return (
    <ModalFooter className={css.ModalFooter}>
      <div className={css.Container}>
        <Button disabled={buttonDisabled} onClick={publishNews}>
          {t('news:actions:publish')}
        </Button>

        <Button
          color="empty"
          disabled={buttonDisabled}
          onClick={openNewsPreview}
        >
          {t('news:actions:preview')}
        </Button>

        <p
          className={cx(
            css.NewsSaveStatus,
            newsSaveStatus === NewsSaveStatusType.SAVE_ERROR &&
              css.NewsSaveStatusError
          )}
        >
          {t(`news:statuses:${newsSaveStatus}`)}
        </p>
      </div>
    </ModalFooter>
  );
};

export default CreateNewsModalFooter;
