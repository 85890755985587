import React, { useEffect } from 'react';

import NewsCategorySelect from './news-category-select';
import useGetNewsCategories from '../../hooks/use-get-news-categories';

interface NewsCategorySelectContainerProps {
  categoryId: string | null;
  categoryIdChange: (categoryId: string) => void;
}

const NewsCategorySelectContainer: React.FC<
  NewsCategorySelectContainerProps
> = ({ categoryId, categoryIdChange }) => {
  const categoriesList = useGetNewsCategories();

  useEffect(() => {
    if (categoriesList && !categoryId) {
      categoryIdChange(categoriesList[0].id);
    }
  }, [categoriesList]);

  if (!categoriesList || !categoryId) {
    return null;
  }

  return (
    <NewsCategorySelect
      category={categoryId}
      categoryChange={categoryIdChange}
      categories={categoriesList}
    />
  );
};

export default NewsCategorySelectContainer;
