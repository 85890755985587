import { EditorBlocksType } from './editor-types';

export const refactorEditorBlocks = (blocks: EditorBlocksType) => {
  return blocks.map((el) => {
    if (typeof el === 'object') {
      'withBorder' in el.data && delete el.data.withBorder;
      'withBackground' in el.data && delete el.data.withBackground;
    }

    return el;
  });
};
