import cx from 'classnames';
import { PropsWithChildren } from 'react';

import { useMediaQuery } from 'hooks/use-media-query.hook';
import { Tooltip } from 'ui/tooltip';

import css from './statistic-info.module.scss';

type Props = {
  align?: 'top' | 'bottom' | 'right' | 'left';
  content: string;
} & PropsWithChildren;

const StatisticInfo = ({ children, align = 'bottom', content }: Props) => {
  const isMobile = useMediaQuery('(max-width: 430px)');

  return (
    <div className={css.root} style={{ cursor: 'pointer' }}>
      {children}
      <Tooltip
        width={210}
        align={align}
        content={content}
        className={css.tooltip}
      >
        <span className={cx(css.question, { [css.hoverable]: !isMobile })}>
          ?
        </span>
      </Tooltip>
    </div>
  );
};

export default StatisticInfo;
