import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface UploadContentVideoMutation {
  uploadContentVideo:
    | {
        __typename: 'UploadContentVideo';
        fileUrl: string;
      }
    | GraphqlErrorType;
}

export interface UploadContentVideoMutationVars {
  file: File;
}

const UPLOAD_CONTENT_VIDEO = gql`
  mutation UploadContentVideo($file: Upload!) {
    uploadContentVideo(file: $file) {
      ... on UploadContentVideo {
        fileUrl
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPLOAD_CONTENT_VIDEO;
