import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface RemoveLiveMarketOrderMutation {
  removeLiveMarketOrder:
    | {
        __typename: 'RemoveLiveMarketOrder';
        order: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface RemoveLiveMarketOrderMutationVars {
  id: string;
}

const REMOVE_LIVE_MARKET_ORDER = gql`
  mutation RemoveLiveMarketOrder($id: ID!) {
    removeLiveMarketOrder(id: $id) {
      __typename
      ... on RemoveLiveMarketOrder {
        order {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default REMOVE_LIVE_MARKET_ORDER;
