import { useTranslation } from 'react-i18next';

import { Loader, Tabs } from 'ui';
import Button from 'ui/button';
import Card from 'ui/card';
import CardHeader from 'ui/card/card-header';
import { Flex } from 'ui/flex';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';

import TransactionsHistoryTable from './transactions-history-table';
import css from './transactions-history.module.scss';
import { useGetTransactionsHistory } from '../../hooks/use-get-transactions-history';

interface Props {
  balanceType: 'vusd' | 'mvusd';
}

const TransactionsHistoryConnected = ({ balanceType }: Props) => {
  const { t } = useTranslation(['common']);

  const { data, loading, onGetNextPage, tabs } =
    useGetTransactionsHistory(balanceType);

  if (loading)
    return (
      <Flex justifyContent="center" alignItems="center">
        <Loader />
      </Flex>
    );

  if (data?.getMyClientTransactions.__typename !== 'GetMyClientTransactions') {
    return null;
  }

  return (
    <Card>
      <ResponsiveGrid gap={GridGap.x3} mobile={{ gap: GridGap.x2 }}>
        <div className={css.Card}>
          <Tabs
            value={tabs.current}
            items={tabs.items}
            onSelect={tabs.setTab}
          />
        </div>
        {data.getMyClientTransactions.transactions.length === 0 ? (
          <Text type="paragraph" textAlign="center" style={{ padding: 20 }}>
            {t('common:labels:no_data')}
          </Text>
        ) : (
          <TransactionsHistoryTable
            transactions={data.getMyClientTransactions.transactions}
          />
        )}
        {data.getMyClientTransactions.nav.hasNext && (
          <Flex justifyContent="center" style={{ padding: 20 }}>
            <Button color="empty" onClick={onGetNextPage} disabled={loading}>
              {t('common:actions:load_more')}
            </Button>
          </Flex>
        )}
      </ResponsiveGrid>
    </Card>
  );
};

export default TransactionsHistoryConnected;
