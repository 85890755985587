import cx from 'classnames';
import React, { ReactNode, Ref, forwardRef } from 'react';

interface VerticalScrollWrapperProps {
  children: ReactNode;
  ref?: Ref<any>;
  className?: string;
}

export const VerticalScrollWrapper = forwardRef<
  HTMLDivElement,
  VerticalScrollWrapperProps
>(({ children, className }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
      }}
      className={cx(className)}
    >
      {children}
    </div>
  );
});

VerticalScrollWrapper.displayName = 'VerticalScrollWrapper';
