import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export const PAGE_LIMIT_PARI = 2;

export type PariStatsOutcome = {
  id: number;
  name: string;
  expiration: boolean;
  win?: boolean;
  sum: number;
  cof: number;
  pnl: number;
};

export type PariStatsEvent = {
  id: number;
  name: string;
  startDate: string;
  outcomes: PariStatsOutcome[];
};

export interface GetPariStatsByEventsQuery {
  getPariStatsByEvents:
    | {
        __typename: 'GetPariStatsByEvents';
        events: PariStatsEvent[];
        nav: {
          limit: number;
          offset: number;
          hasNext: boolean;
        };
      }
    | GraphqlErrorType;
}

export type GetPariStatsByEventsQueryVars = {
  nav: {
    limit: number;
    offset: number;
  };
  sort: {
    eventStartDate?: 'ASC' | 'DESC';
  };
};

const GET_PARI_STATS_EVENTS = gql`
  query GetPariStatsByEvents(
    $nav: GetPariStatsByEventsNavQuery!
    $sort: GetPariStatsLiveEventsSortQuery!
  ) {
    getPariStatsByEvents(nav: $nav, sort: $sort) {
      __typename
      ... on GetPariStatsByEvents {
        events {
          id
          name
          startDate
          outcomes {
            id
            name
            expiration
            win
            sum
            cof
            pnl
          }
        }
        nav {
          limit
          offset
          hasNext
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_PARI_STATS_EVENTS;
