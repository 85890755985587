import { useState } from 'react';

import { useAppConfig } from 'application/context/app-config-provider';
import webApp from 'application/web-app';

import WalletsList, { WalletsListProps } from './wallets-list';
import { useAppMetamask } from '../../context/app-metamask-context';
import useRemoveWalletAddressClient from '../../hooks/use-remove-wallet-address-client';

const WalletsListContainer: React.FC<
  Pick<WalletsListProps, 'wallets' | 'limits'>
> = ({ wallets, limits }) => {
  const { appConfig } = useAppConfig();

  const { account, approve, revoke } = useAppMetamask();
  const [procesing, setProcessing] = useState(false);

  const removeWalletAddress = useRemoveWalletAddressClient();

  return (
    <WalletsList
      walletEnabled={appConfig?.walletEnabled || false}
      account={account}
      wallets={wallets}
      limits={limits}
      processing={procesing}
      onRemove={async (id) => {
        try {
          setProcessing(true);
          await removeWalletAddress(id);
        } catch (e: unknown) {
          webApp.log.exception(e);
        } finally {
          setProcessing(false);
        }
      }}
      onApprove={async () => {
        if (!appConfig?.walletEnabled) {
          return;
        }
        approve(1000);
      }}
      onRevoke={async () => {
        revoke();
      }}
    />
  );
};

export default WalletsListContainer;
