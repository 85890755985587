import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type NewsReviewingStatusType = {
  id: string;
  status: string;
};

export interface GetNewsReviewingStatusesListQuery {
  getNewsReviewingStatusesList:
    | {
        __typename: 'GetNewsReviewingStatusesList';
        statuses: NewsReviewingStatusType[];
      }
    | GraphqlErrorType;
}

const GET_NEWS_REVIEWING_STATUSES_LIST = gql`
  query GetNewsReviewingStatusesList {
    getNewsReviewingStatusesList {
      __typename
      ... on GetNewsReviewingStatusesList {
        statuses {
          id
          status
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_REVIEWING_STATUSES_LIST;
