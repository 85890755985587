import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_MY_SOCIAL_RATING, {
  GetMySocialRatingQuery,
  GetMySocialRatingQueryVars,
} from '../graphql/get-my-social-rating';

export const useGetMySocialRating = () => {
  const { data, error } = useQuery<
    GetMySocialRatingQuery,
    GetMySocialRatingQueryVars
  >(GET_MY_SOCIAL_RATING, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
      return;
    }

    if (data?.getMySocialRating.__typename === 'Error') {
      Toast.error(data.getMySocialRating.error);
    }
  }, [data, error]);

  return data?.getMySocialRating.__typename === 'GetMySocialRating'
    ? data.getMySocialRating.rating.toString()
    : '';
};
