import React, { ReactNode, useContext } from 'react';

import RadioGroupContext from 'ui/radio/radio-group.context';
import { Text } from 'ui/v2/typography/text';

import css from './radio.module.scss';

interface RadioProps {
  children: ReactNode;
  value: number | string;
  checked?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  value,
  children,
  checked = false,
}) => {
  const context = useContext(RadioGroupContext);

  return (
    <div className={css.radio} onClick={() => context.onChange?.(value)}>
      <div className={`${css.radio__btn} ${checked ? css.active : ''}`}>
        {checked ? <div className={css.radio__btn_checked}></div> : null}
      </div>
      <Text type="paragraph">{children}</Text>
    </div>
  );
};
