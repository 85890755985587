import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export type OneUnpublishedNewsDataType = Omit<
  OnePublishedNewsDataType,
  'client' | 'commentsCount' | 'isLiked' | 'likesCount'
> & { status: string };

export interface GetOneUnpublishedNewsQuery {
  getOneUnpublishedNews:
    | {
        __typename: 'GetOneUnpublishedNews';
        news: OneUnpublishedNewsDataType;
      }
    | GraphqlErrorType;
}

export interface GetOneUnpublishedNewsQueryVars {
  id: string;
}

const GET_ONE_UNPUBLISHED_NEWS = gql`
  query GetOneUnpublishedNews($id: ID!) {
    getOneUnpublishedNews(id: $id) {
      ... on GetOneUnpublishedNews {
        news {
          id
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          date
          title
          previewDescription
          previewImage
          previewVideo
          status
          blocks {
            type
            id
            data {
              text
              caption
              alignment
              level
              items
              style
              stretched
              url
              serviceName
              file {
                url
              }
              html
              url
              serviceName
            }
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_ONE_UNPUBLISHED_NEWS;
