import cx from 'classnames';
import React, { useRef, useState } from 'react';

import { ArrowDownIcon } from 'ui/icons';

import css from './accordion.module.scss';

interface AccordionProps {
  label: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  className?: string;
  childrenClassName?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  defaultOpen = false,
  children,
  className,
  childrenClassName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(defaultOpen);

  return (
    <>
      <div
        className={cx(className && className, css.Root)}
        onClick={() => setOpen(!open)}
      >
        {label}
        <div className={open ? css.ArrowOpen : css.Arrow}>
          <ArrowDownIcon />
        </div>
      </div>
      <div
        className={cx(childrenClassName && childrenClassName, css.Accordin)}
        style={{
          overflow: open ? 'visible' : 'hidden',
          height: open ? 'auto' : '0',
          transform: open ? 'scaleY(1)' : 'scaleY(0)',
        }}
      >
        <div ref={ref}>{children}</div>
      </div>
    </>
  );
};

export default Accordion;
