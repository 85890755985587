import { useEffect, useState } from 'react';

import useDebounce from '../application/hooks/use-debounce';

const useInfiniteScroll = (
  loadMore: boolean,
  scrollThresholdPercentage: number,
  callback: () => void
) => {
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [shouldInvokeCallback, setShouldInvokeCallback] = useState(false);
  const [isCallbackExecuting, setCallbackExecuting] = useState(false);
  const [loading, setLoading] = useState(false);

  const scrollChange = async () => {
    const currentScrollPosition =
      window.scrollY || document.documentElement.scrollTop;

    if (currentScrollPosition < lastScrollPosition) return;

    const scrollThreshold =
      currentScrollPosition + window.innerHeight >=
      document.documentElement.scrollHeight * scrollThresholdPercentage;

    if (scrollThreshold && !isCallbackExecuting) {
      setShouldInvokeCallback(true);
    }

    setLastScrollPosition(currentScrollPosition);
  };

  const debouncedScrollChange = useDebounce(scrollChange, 700);

  useEffect(() => {
    if (!shouldInvokeCallback || isCallbackExecuting || !loadMore) return;

    const invokeCallback = async () => {
      setLoading(true);
      setCallbackExecuting(true);
      await callback();
      setCallbackExecuting(false);
      setShouldInvokeCallback(false);
      setLoading(false);
    };

    invokeCallback();
  }, [
    shouldInvokeCallback,
    setShouldInvokeCallback,
    isCallbackExecuting,
    setCallbackExecuting,
    setLoading,
  ]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollChange);
    return () => window.removeEventListener('scroll', debouncedScrollChange);
  }, [debouncedScrollChange]);

  return loading;
};

export default useInfiniteScroll;
