import cx from 'classnames';
import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatDatetimeToFull } from 'application/utils/date';
import { roundMoney } from 'application/utils/money';
import webApp from 'application/web-app';
import { StickyColumn } from 'features/sticky-column/sticky-column';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';

import { StatsEvent } from '../../graphql/get-user-statistic-live-orders';
import css from '../../user-statistic-events.module.scss';
import { PnlPrint } from '../pnl-print/PnlPrint';

interface Props {
  ordersList: StatsEvent[];
  gridTemplateColumns: string;
  gridTemplateColumnsTablet: string;
  gridTemplateColumnsMobile: string;
}

const EventsListTableContent = ({
  ordersList,
  gridTemplateColumns,
  gridTemplateColumnsTablet,
  gridTemplateColumnsMobile,
}: Props) => {
  const { t } = useTranslation(['events', 'common', 'fights']);

  return (
    <>
      {ordersList.map((el, index) => {
        const allPnl = roundMoney(
          el.outcomes.reduce((acc, outcome) => acc + outcome.allPnl, 0)
        );

        return (
          <div key={el.id}>
            <ResponsiveGrid
              alignItems="center"
              gap={GridGap.x2}
              gridTemplateColumns={gridTemplateColumns}
              mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
              tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
              gridAutoRows="minmax(74px, 1fr)"
              key={'event_' + el.id}
              className={cx(css.Table, css.Border_top, {
                [css.Gray_back]: index % 2 === 0,
              })}
            >
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
                style={{ whiteSpace: 'wrap' }}
              >
                {formatDatetimeToFull(new Date(el.startDate))}
              </Text>
              <Text
                type="paragraph"
                textAlign="center"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
              >
                {el.id}
              </Text>
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
              >
                <Link href={webApp.router.getTournamnt(el.tournamentId)}>
                  {el.name}
                </Link>
              </Text>
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <Text type="paragraph" />
              <StickyColumn>
                <PnlPrint value={allPnl} />
              </StickyColumn>
            </ResponsiveGrid>
            {el.outcomes.map((el) => {
              return (
                <ResponsiveGrid
                  alignItems="center"
                  gap={GridGap.x2}
                  gridTemplateColumns={gridTemplateColumns}
                  mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
                  tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
                  gridAutoRows="40px"
                  key={'event_' + el.id}
                  className={cx(css.Table, css.Border_top, {
                    [css.Gray_back]: index % 2 === 0,
                  })}
                >
                  <Text type="paragraph" />
                  <Text type="paragraph" />
                  <Text type="paragraph" />
                  <Text
                    type="paragraph"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.name}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.expiration
                      ? t('events:close_deal')
                      : t('events:active_deal')}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.expiration ? el.quantity : '-'}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {!el.expiration ? el.quantity : '-'}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.currentPrice}
                  </Text>
                  <PnlPrint value={el.unrealisedPnl} />
                  <PnlPrint value={el.orderPnl} />
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.expiration
                      ? t('common:labels:yes')
                      : t('common:labels:no')}
                  </Text>
                  <Text
                    type="paragraph"
                    textAlign="center"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    {el.expiration
                      ? el.win
                        ? t('fights:win')
                        : t('fights:loss')
                      : '-'}
                  </Text>
                  <StickyColumn>
                    <PnlPrint value={el.allPnl} />
                  </StickyColumn>
                </ResponsiveGrid>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default EventsListTableContent;
