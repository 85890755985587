import React, { ChangeEvent, SyntheticEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Crop,
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from 'react-image-crop';

import { Toast } from 'ui';
import ConfirmModal from 'ui/confirm-modal';

import UploadClientAvatar from './upload-client-avatar';
import { validateImage } from '../../../../application/utils/validations';
import useDeleteClientAvatar from '../../hooks/use-delete-client-avatar';
import useUploadClientAvatar from '../../hooks/use-upload-client-avatar';
import UploadClientAvatarModal from '../upload-client-avatar-modal';

export const ASPECT_RATIO = 1;
export const MIN_DIMENSION = 50;

interface UploadClientAvatarContainerProps {
  size: 'M' | 'L';
  avatar: string | null;
}

const UploadClientAvatarContainer: React.FC<
  UploadClientAvatarContainerProps
> = ({ avatar, size }) => {
  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null);
  const [imageType, setImageType] = useState<string>('');
  const [crop, setCrop] = useState<Crop>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [isDeleteAvatarModalOpen, setDeleteAvatarModalOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const input = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const { t } = useTranslation(['account', 'common']);
  const uploadAvatarMutation = useUploadClientAvatar();
  const deleteClientAvatar = useDeleteClientAvatar();

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (!file) return;

    const isImageVerifiedSuccessfully = validateImage(file);
    if (!isImageVerifiedSuccessfully) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || '';
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', (e: any) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;

        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          Toast.error(t('account:errors:avatar_upload_format_error'));
          setImgUrl('');
          return;
        }
      });

      setImageType(file.type);
      setImgUrl(imageUrl);
    });

    reader.readAsDataURL(file);
  };

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: MIN_DIMENSION,
      },
      ASPECT_RATIO,
      naturalWidth,
      naturalHeight
    );

    const centeredCrop = centerCrop(crop, naturalWidth, naturalHeight);
    setCrop(centeredCrop);
  };

  const cropImage = () => {
    if (!imgRef.current || !crop) {
      Toast.error(t('common:errors:something_went_wrong'));
      return;
    }

    const currentCrop = convertToPixelCrop(
      crop,
      imgRef.current.width,
      imgRef.current.height
    );

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      Toast.error(t('common:errors:something_went_wrong'));
      return;
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;

    canvas.width = Math.floor(currentCrop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(currentCrop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';
    ctx.save();

    const cropX = currentCrop.x * scaleX;
    const cropY = currentCrop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      imgRef.current,
      0,
      0,
      imgRef.current.naturalWidth,
      imgRef.current.naturalHeight,
      0,
      0,
      imgRef.current.naturalWidth,
      imgRef.current.naturalHeight
    );

    ctx.restore();

    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], 'cropped-image', { type: imageType });
        setCroppedImageFile(file);
      }
    }, imageType);
  };

  const uploadAvatar = async () => {
    if (!croppedImageFile) return;

    const fileUrl = await uploadAvatarMutation(croppedImageFile);
    if (fileUrl) {
      clearReactCrop();
      isMobileMenuOpen && setMobileMenuOpen(false);
    }
  };

  const clearReactCrop = () => {
    setImgUrl('');
    setModalOpen(false);
    setMobileMenuOpen(false);
    setCroppedImageFile(null);
    setCrop(undefined);
    if (input.current) {
      input.current.value = '';
    }
  };

  const deleteAvatar = async () => {
    await deleteClientAvatar();
    setDeleteAvatarModalOpen(false);
  };

  return (
    <>
      <UploadClientAvatar
        avatar={avatar}
        size={size}
        inputRef={input}
        isMobileMenuOpen={isMobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        modalOpen={() => setModalOpen(true)}
        deleteAvatar={() => setDeleteAvatarModalOpen(true)}
        onChangeFile={onChangeFile}
      />

      {isModalOpen && (
        <UploadClientAvatarModal
          crop={crop}
          imgRef={imgRef}
          imgUrl={imgUrl}
          cropImage={cropImage}
          isModalOpen={isModalOpen}
          croppedImageFile={croppedImageFile}
          setCrop={setCrop}
          clearReactCrop={clearReactCrop}
          uploadAvatar={uploadAvatar}
          onImageLoad={onImageLoad}
          uploadFile={() => input?.current?.click()}
        />
      )}

      {isDeleteAvatarModalOpen && (
        <ConfirmModal
          isModalOpen={isDeleteAvatarModalOpen}
          modalTitle={t('account:labels:delete_avatar')}
          callback={deleteAvatar}
          closeModal={() => setDeleteAvatarModalOpen(false)}
        >
          <p>{t('account:actions:avatar_delete_confirm')}</p>
        </ConfirmModal>
      )}
    </>
  );
};

export default UploadClientAvatarContainer;
