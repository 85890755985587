import React from 'react';

import ModeratedNewsListFilters from './moderated-news-list-filters';
import useGetEventSportsList from '../../hooks/use-get-event-sports-list';
import useGetNewsCategories from '../../hooks/use-get-news-categories';
import useGetNewsReviewingStatusesList from '../../hooks/use-get-news-reviewing-statuses-list';
import {
  ModeratedNewsListFiltersType,
  updateFiltersType,
} from '../../hooks/use-set-moderated-news-list-filters';

interface ModeratedNewsListFiltersContainerProps {
  filters: ModeratedNewsListFiltersType;
  updateFilters: updateFiltersType;
  resetAllFilters: () => void;
}

const ModeratedNewsListFiltersContainer: React.FC<
  ModeratedNewsListFiltersContainerProps
> = ({ filters, updateFilters, resetAllFilters }) => {
  const sportsList = useGetEventSportsList();
  const categoriesList = useGetNewsCategories();
  const statusesList = useGetNewsReviewingStatusesList();

  if (!sportsList || !categoriesList || !statusesList) {
    return null;
  }

  return (
    <ModeratedNewsListFilters
      filters={filters}
      sportsList={sportsList}
      categoriesList={categoriesList}
      resetAllFilters={resetAllFilters}
      statusesList={statusesList}
      dateChange={(date) => updateFilters('sort', date)}
      sportChange={(sport) => updateFilters('sports', sport)}
      categoryChange={(category) => updateFilters('categories', category)}
      statusChange={(status) => updateFilters('status', status)}
    />
  );
};

export default ModeratedNewsListFiltersContainer;
