import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface UploadContentImageMutation {
  uploadContentImage:
    | {
        __typename: 'UploadContentImage';
        fileUrl: string;
      }
    | GraphqlErrorType;
}

export interface UploadContentImageMutationVars {
  file: File;
}

const UPLOAD_CONTENT_IMAGE = gql`
  mutation UploadContentImage($file: Upload!) {
    uploadContentImage(file: $file) {
      ... on UploadContentImage {
        fileUrl
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPLOAD_CONTENT_IMAGE;
