import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import PUBLISH_NEWS, {
  PublishNewsMutation,
  PublishNewsMutationVars,
} from '../graphql/publish-news';

const usePublishNews = () => {
  const { t } = useTranslation(['news']);
  const [publishNewsMutation] = useMutation<
    PublishNewsMutation,
    PublishNewsMutationVars
  >(PUBLISH_NEWS);

  return async (id: string) => {
    try {
      const { data, errors } = await publishNewsMutation({
        variables: { id },
      });

      const isPublishNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isPublishNewsSuccess) return;

      if (data?.publishNews.__typename === 'Error') {
        Toast.error(data.publishNews.error);
        return;
      }

      if (data?.publishNews.__typename === 'PublishNews') {
        return data.publishNews.news.id;
      }
    } catch (e: unknown) {
      Toast.error(t('common:errors:something_went_wrong'));
    }
  };
};

export default usePublishNews;
