import React, { ReactNode } from 'react';

import { ChallengeItem } from 'ui/challenges/challenge-item';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

export interface ChallengesProps {
  challenges: {
    text: string;
    desc: string;
    active?: boolean;
    img: ReactNode;
  }[];
}

export const Challenges: React.FC<ChallengesProps> = ({ challenges }) => {
  return (
    <Flex gap={GridGap.x4} wrap="wrap" justifyContent="space-between">
      {challenges.map((item, index) => (
        <ChallengeItem
          style={{ zIndex: challenges.length - index }}
          item={item}
          key={index}
        />
      ))}
    </Flex>
  );
};
