import React from 'react';

import { Button } from 'ui';
import Card from 'ui/card';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { Trash } from 'ui/icons';

import css from './unpublished-news-preview.module.scss';
import {
  News,
  UnpublishedNewsStatusType,
} from '../../graphql/get-my-unpublished-news-list';
import NewsPreview from '../news-preview';
import UnpublishedNewsStatus from '../unpublished-news-status';

interface UnpublishedNewsProps {
  newsData: News;
  openUnpublishedNews: () => void;
  deleteUnpublishedNews?: () => void;
}

const UnpublishedNewsPreview: React.FC<UnpublishedNewsProps> = ({
  newsData,
  deleteUnpublishedNews,
  openUnpublishedNews,
}) => (
  <Card className={css.Root}>
    <Grid
      className={css.Header}
      alignItems="center"
      gridTemplateColumns="1fr auto"
      marginBottom={GridGap.x2}
    >
      {newsData.status && (
        <UnpublishedNewsStatus newsStatus={newsData.status} />
      )}

      {deleteUnpublishedNews && (
        <Button
          icon={<Trash />}
          color="empty"
          disabled={newsData.status === UnpublishedNewsStatusType.MODERATING}
          className={css.Delete}
          onClick={deleteUnpublishedNews}
        />
      )}
    </Grid>

    <NewsPreview
      newsPreviewData={newsData}
      editor={false}
      openNews={openUnpublishedNews}
    />
  </Card>
);

export default UnpublishedNewsPreview;
