import { useState } from 'react';

import { Card } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import Switch from 'ui/switch';

import LiveEventsList from './components/live-events-list';
import PariEventsList from './components/pari-events-list';
import css from './user-statistic-events.module.scss';

export const UserEventsStatistic = () => {
  const [orderType, setOrderType] = useState<'live' | 'pari'>('live');

  return (
    <Card>
      <ResponsiveGrid
        gap={GridGap.x3}
        mobile={{ gap: GridGap.x2 }}
        className={css.Wrapper}
      >
        <Flex justifyContent="flex-end" className={css.Card}>
          <Switch
            value={orderType}
            items={[
              { value: 'live', label: 'live' },
              { value: 'pari', label: 'pari' },
            ]}
            onChange={setOrderType}
          />
        </Flex>
        {orderType === 'live' && <LiveEventsList />}
        {orderType === 'pari' && <PariEventsList />}
      </ResponsiveGrid>
    </Card>
  );
};
