import React, { useState } from 'react';

import ModeratedNewsListItem from './moderated-news-list-item';
import { OneModeratedNewsDataType } from '../../graphql/get-moderation-news-list';
import { ModeratedNewsListReviewingStatusesType } from '../../hooks/use-set-moderated-news-list-filters';
import ModeratedNewsModal from '../moderated-news-modal';

interface ModeratedNewsContainerProps {
  news: OneModeratedNewsDataType;
}

const ModeratedNewsContainer: React.FC<ModeratedNewsContainerProps> = ({
  news,
}) => {
  const [isModeratedNewsModalOpen, setModeratedNewsModalOpen] = useState(false);

  const openNews = () => {
    if (news.reviewInfo.id === ModeratedNewsListReviewingStatusesType.OTHER) {
      return;
    }
    setModeratedNewsModalOpen(true);
  };

  return (
    <>
      <ModeratedNewsListItem news={news} openModeratedNews={openNews} />

      {isModeratedNewsModalOpen && (
        <ModeratedNewsModal
          id={news.id}
          closeModal={() => setModeratedNewsModalOpen(false)}
        />
      )}
    </>
  );
};

export default ModeratedNewsContainer;
