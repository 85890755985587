import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export interface ReviewInfoType {
  id: string;
  status: string;
}

export type OneModeratedNewsDataType = Omit<
  OnePublishedNewsDataType,
  'commentsCount' | 'isLiked' | 'likesCount'
> & { reviewInfo: ReviewInfoType };

export interface GetModeratedNewsListQuery {
  getModeratedNewsList:
    | {
        __typename: 'GetModeratedNewsList';
        news: OneModeratedNewsDataType[];
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface GetModeratedNewsListFilterVars {
  categories?: string[];
  sports?: string[];
  lastNewsDate?: string;
  status?: string;
}

export interface GetModeratedNewsListVars {
  pageNumber: string;
  sort: string;
  filter?: GetModeratedNewsListFilterVars;
}

const GET_MODERATED_NEWS_LIST = gql`
  query GetModeratedNewsList(
    $pageNumber: String!
    $filter: Filter
    $sort: String
  ) {
    getModeratedNewsList(
      pageNumber: $pageNumber
      filter: $filter
      sort: $sort
    ) {
      ... on GetModeratedNewsList {
        news {
          id
          title
          previewDescription
          previewUploadDescription
          previewImage
          previewVideo
          reviewInfo {
            id
            status
          }
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          date
          client {
            id
            name
            avatar
          }
        }
        totalCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MODERATED_NEWS_LIST;
