export function removeTypename<T>(value: T): T {
  if (Array.isArray(value)) {
    return value.map((item) => removeTypename(item)) as unknown as T;
  } else if (value && typeof value === 'object') {
    return Object.keys(value).reduce(
      (acc, key) => {
        if (key !== '__typename') {
          (acc as any)[key] = removeTypename((value as any)[key]);
        }
        return acc;
      },
      Array.isArray(value) ? [] : {}
    ) as T;
  }
  return value;
}
