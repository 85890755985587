import React from 'react';

import { GraphqlErrorType } from '../../../../application/graphql/graphql-types';

type GraphqlDataType<Field extends string, Value> = Value & {
  __typename: Capitalize<Field>;
};

interface DataWrapperProps<Field extends string, Value> {
  children: (value: GraphqlDataType<Field, Value>) => JSX.Element;
  value: GraphqlDataType<Field, Value> | GraphqlErrorType;
  fallback: JSX.Element;
}

export function isGraphqlError<Data extends { __typename: string }>(
  data: Data | GraphqlErrorType
): data is GraphqlErrorType {
  return data.__typename === 'Error';
}

export const QueryErrorGuard = <
  Field extends string,
  Value extends { __typename: string }
>({
  children,
  value,
  fallback = <></>,
}: DataWrapperProps<Field, Value>) => {
  return isGraphqlError(value) ? fallback : children(value);
};
