import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface DeletePublishedNewsMutation {
  deletePublishedNews:
    | {
        __typename: 'DeletePublishedNews';
        newsId: string;
      }
    | GraphqlErrorType;
}

export interface DeletePublishedNewsMutationVars {
  id: string;
}

const DELETE_PUBLISHED_NEWS = gql`
  mutation DeletePublishedNews($id: ID!) {
    deletePublishedNews(id: $id) {
      ... on DeletePublishedNews {
        newsId
      }
      ... on Error {
        error
      }
    }
  }
`;

export default DELETE_PUBLISHED_NEWS;
