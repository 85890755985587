import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { formatDatetimeToFull } from 'application/utils/date';
import { StickyColumn } from 'features/sticky-column/sticky-column';
import CloseInputIcon from 'ui/assets/icons/28/close_input.svg';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Text } from 'ui/v2/typography/text';

import css from './live-orders-list.module.scss';
import { StatsOrder } from '../../graphql/get-user-statistic-live-orders';

interface Props {
  ordersList: StatsOrder[];
  onDeleteOrder: (id: string) => void;
  isLoading?: boolean;
  gridTemplateColumns: string;
  gridTemplateColumnsTablet: string;
  gridTemplateColumnsMobile: string;
}

const OrderListTableContent = ({
  ordersList,
  onDeleteOrder,
  isLoading,
  gridTemplateColumns,
  gridTemplateColumnsTablet,
  gridTemplateColumnsMobile,
}: Props) => {
  const { t } = useTranslation(['live-orders']);

  return (
    <>
      {ordersList.map((el, index) => {
        return (
          <ResponsiveGrid
            alignItems="center"
            gap={GridGap.x2}
            gridTemplateColumns={gridTemplateColumns}
            mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
            tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
            gridAutoRows="minmax(74px, 1fr)"
            key={el.orderUniqueId}
            className={cx(css.Table, css.Border_top, {
              [css.Gray_back]: index % 2 === 0,
            })}
          >
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              className={css.Wrap}
            >
              {formatDatetimeToFull(new Date(el.createdAt))}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              className={css.Wrap}
            >
              {el.eventName}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              className={css.Wrap}
            >
              {el.tokenName}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {t(`live_orders:type:${el.operationType}`)}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {el.orderType === 'limit' ? 'L' : 'M'}
              {el.eventId}.{el.id}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {el.quantity}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {el.orderPrice || t('live_orders:labels:market')}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {el.executedQuantity}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {Math.abs(el.executionPrice)}
            </Text>
            <Text
              type="paragraph"
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              {el.volume}
            </Text>
            <StickyColumn>
              <Flex
                alignItems="center"
                gap={GridGap.x1}
                justifyContent="flex-start"
              >
                <Text
                  type="paragraph"
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  textAlign="center"
                >
                  {t(`live_orders:status:${el.status}`)}
                </Text>
                {!el.closed && (
                  <button
                    className={css.Trash}
                    disabled={isLoading}
                    onClick={() => onDeleteOrder(el.id)}
                  >
                    <CloseInputIcon />
                  </button>
                )}
              </Flex>
            </StickyColumn>
          </ResponsiveGrid>
        );
      })}
    </>
  );
};

export default OrderListTableContent;
