import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';
import { EditorBlockDataType } from 'ui/editor';

import { NewsFormPayloadType } from '../types/news-form-payload-type';

export interface SaveNewsMutation {
  saveNews:
    | {
        __typename: 'SaveNews';
        news: {
          id: string;
        };
        totalCount: number;
      }
    | GraphqlErrorType;
}

export interface NewsBlock {
  id?: string;
  type: string;
  data: EditorBlockDataType;
}

export interface SaveNewsMutationVars {
  id: string | null;
  input?: NewsFormPayloadType;
}

const SAVE_NEWS = gql`
  mutation SaveNews($input: SaveNewsInput!, $id: ID) {
    saveNews(input: $input, id: $id) {
      ... on SaveNews {
        news {
          id
        }
        totalCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default SAVE_NEWS;
