import React from 'react';

import { Select } from 'ui';

import css from './news-category-select.module.scss';
import { CategoryType } from '../../graphql/get-news-categories';

interface NewsCategorySelectProps {
  category: string;
  categories: CategoryType[];
  categoryChange: (category: string) => void;
}

const NewsCategorySelect: React.FC<NewsCategorySelectProps> = ({
  category,
  categories,
  categoryChange,
}) => {
  return (
    <div className={css.Select}>
      <Select
        value={category}
        items={categories.map((category) => ({
          value: category.id,
          label: category.name,
        }))}
        onChange={categoryChange}
      />
    </div>
  );
};

export default NewsCategorySelect;
