import React, { useState } from 'react';

import UnpublishedNewsPreview from './unpublished-news-preview';
import { News } from '../../graphql/get-my-unpublished-news-list';
import useDeleteMyUnpublishedNews from '../../hooks/use-delete-my-unpublished-news';
import DeleteNewsModal from '../../modals/delete-news-modal';

interface UnpublishedNewsPreviewContainerProps {
  newsData: News;
  openUnpublishedNews: () => void;
  deleteUnpublishedNews?: () => void;
}

const UnpublishedNewsPreviewContainer: React.FC<
  UnpublishedNewsPreviewContainerProps
> = ({ newsData, openUnpublishedNews, deleteUnpublishedNews }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const deleteNews = useDeleteMyUnpublishedNews();

  const onNewsDelete = async () => {
    if (!deleteUnpublishedNews) return;

    setModalOpen(false);

    const result = await deleteNews(newsData.id);
    if (result) {
      deleteUnpublishedNews();
    }
  };

  const deleteNewsModalOpen = () => {
    if (!deleteUnpublishedNews) return;
    setModalOpen(true);
  };

  return (
    <>
      <UnpublishedNewsPreview
        newsData={newsData}
        deleteUnpublishedNews={deleteUnpublishedNews && deleteNewsModalOpen}
        openUnpublishedNews={openUnpublishedNews}
      />

      {isModalOpen && (
        <DeleteNewsModal
          title={newsData.title}
          isModalOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          callback={onNewsDelete}
        />
      )}
    </>
  );
};

export default UnpublishedNewsPreviewContainer;
