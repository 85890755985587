import React from 'react';

import BookmarksNewsList from './bookmarks-news-list';
import useInfiniteBookmarksNews from '../../hooks/use-infinite-bookmarks-news';

export interface FavoriteNewsListProps {}

const BookmarksNewsListContainer: React.FC<FavoriteNewsListProps> = () => {
  const { data, loading } = useInfiniteBookmarksNews();

  if (data?.getBookmarksNewsList.__typename !== 'GetBookmarksNewsList') {
    return null;
  }

  return (
    <BookmarksNewsList
      news={data.getBookmarksNewsList.news}
      loading={loading}
    />
  );
};

export default BookmarksNewsListContainer;
