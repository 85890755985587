import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type CategoryType = {
  name: string;
  id: string;
};

export interface GetNewsCategoriesQuery {
  getNewsCategories:
    | {
        __typename: 'GetNewsCategories';
        categories: CategoryType[];
      }
    | GraphqlErrorType;
}

const GET_NEWS_CATEGORIES = gql`
  query GetNewsCategories {
    getNewsCategories {
      ... on GetNewsCategories {
        categories {
          name
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_CATEGORIES;
