import Head from 'next/head';
import React, { useState } from 'react';

import { MyClientModel } from 'application/graphql/get-my-client';
import WebApp from 'application/web-app';
import CreateNewsModal from 'features/news/components/create-news-modal';
import Footer from 'ui/footer';
import Header from 'ui/header';

import css from './general-layout.module.scss';

interface GeneralLayoutProps {
  user: MyClientModel | null;
  title?: string;
  description?: string;
  keywords?: string[];
  imgPreview?: string;
  headerUrls: { label: string; url: string }[];
  footerUrls: { label: string; url: string }[];
  footer?: boolean;
  children: React.ReactNode;
  userLogout: () => void;
}

const GeneralLayout: React.FC<GeneralLayoutProps> = ({
  user,
  headerUrls,
  footerUrls,
  children,
  footer = true,
  userLogout,
  ...meta
}) => {
  const [isCreateNewsModalOpen, setCreateNewsModalOpen] = useState(false);

  return (
    <>
      <Head>
        {meta.title && (
          <>
            <title>VoteSport - {meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta name="twitter:title" content={meta.title} />
          </>
        )}
        {meta.keywords && (
          <meta name="keywords" content={meta.keywords.join(',')} />
        )}
        {meta.description && (
          <>
            <meta property="og:description" content={meta.description} />
            <meta name="twitter:description" content={meta.description} />
          </>
        )}
        {meta.imgPreview && (
          <>
            <meta property="og:image" content={meta.imgPreview} />
            <meta name="twitter:image" content={meta.imgPreview} />
          </>
        )}
        <meta
          name="viewport"
          content="initial-scale=1.0, user-scalable=no, width=device-width"
        />
        <meta name="robots" content="index, follow" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <div className={css.Root}>
        <Header
          user={user}
          headerUrls={headerUrls}
          userLogout={userLogout}
          appLanguages={WebApp.appLanguages}
          onCreateNewsModalOpen={() => setCreateNewsModalOpen(true)}
        />
        <main className={css.Main}>{children}</main>
        {footer && <Footer footerUrls={footerUrls} />}
      </div>
      {isCreateNewsModalOpen && (
        <CreateNewsModal
          closeModal={() => {
            WebApp.eventEmitter.emitCreateNewsModalClose();
            setCreateNewsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default GeneralLayout;
