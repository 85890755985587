import i18n from 'i18next';

import { minTwoDigits } from './numbers';

export const formatTime = (date: Date) =>
  `${minTwoDigits(date.getHours())}:${minTwoDigits(date.getMinutes())}`;

export const formatDatetime = (date: Date) => {
  return `${date.getDate()} ${i18n.t(
    `period:months_full.${date.getMonth()}`
  )} ${minTwoDigits(date.getHours())}:${minTwoDigits(date.getMinutes())}`;
};

export const formatDatetimeWithYear = (date: Date) => {
  return `${date.getDate()} ${i18n.t(
    `period:months_full.${date.getMonth()}`
  )} ${date.getFullYear()}, ${minTwoDigits(date.getHours())}:${minTwoDigits(
    date.getMinutes()
  )}`;
};

export const formatDatetimeDayMonth = (date: Date) => {
  return `${date.getDate()} ${i18n.t(`period:months_full.${date.getMonth()}`)}`;
};

export const formatShortDate = (date: Date) => {
  return `${date.getDate()} ${i18n.t(
    `period:months_short.${date.getMonth()}`
  )} ${date.getFullYear()}`;
};

export const formatShortDateWithoutYear = (date: Date) => {
  return `${date.getDate()} ${i18n.t(
    `period:months_short.${date.getMonth()}`
  )}`;
};

export const formatDatetimeToFull = (date: Date, short = false) => {
  if (short) {
    return `${date.getDate()} ${i18n.t(
      `period:months_short.${date.getMonth()}`
    )}, ${minTwoDigits(date.getHours())}:${minTwoDigits(date.getMinutes())}`;
  }

  return `${date.getDate()} ${i18n.t(
    `period:months_short.${date.getMonth()}`
  )} ${date.getFullYear()}, ${minTwoDigits(date.getHours())}:${minTwoDigits(
    date.getMinutes()
  )}`;
};

export const formatDate = (date: Date, short = false) => {
  if (short) {
    return `${date.getDate()} ${i18n.t(
      `period:months_short.${date.getMonth()}`
    )} ${date.getFullYear()}`;
  }

  return `${date.getDate()} ${i18n.t(
    `period:months_full.${date.getMonth()}`
  )} ${date.getFullYear()}`;
};

export const dateToText = (date: Date) => {
  const diff = new Date().getTime() - date.getTime();

  if (diff < 1000 * 3600) {
    const value = Math.ceil(diff / 1000 / 60);

    return `${i18n.t('period:minute', { count: value })} ${i18n.t(
      'period:ago'
    )}`;
  }

  if (diff < 1000 * 3600 * 12) {
    const value = Math.ceil(diff / 1000 / 60 / 60);

    return `${i18n.t('period:hour', { count: value })} ${i18n.t('period:ago')}`;
  }

  if (diff < 1000 * 86400) {
    return `${date.getDate()} ${i18n.t(
      `period:months_short.${date.getMonth()}`
    )} в ${minTwoDigits(date.getHours())}:${minTwoDigits(date.getMinutes())}`;
  }

  return `${date.getDate()} ${i18n.t(
    `period:months_short.${date.getMonth()}`
  )} ${date.getFullYear()} ${i18n.t('period:at')} ${minTwoDigits(
    date.getHours()
  )}:${minTwoDigits(date.getMinutes())}`;
};

export const dateToTextShort = (date: Date) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const diff = now.getTime() - date.getTime();
  const diffSeconds = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diff / 60000);
  const diffHours = Math.floor(diff / 3600000);
  const diffDays = Math.floor(diff / (24 * 3600000));
  const diffWeeks = Math.floor(diffDays / 7);

  if (dateDay.getTime() === today.getTime()) {
    if (diffSeconds < 1) {
      return i18n.t('period:now');
    } else if (diffSeconds < 60) {
      return `${diffSeconds}${i18n.t('period:seconds')}`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes}${i18n.t('period:minutes')}`;
    } else {
      return `${diffHours}${i18n.t('period:hours')}`;
    }
  } else if (diffHours < 24) {
    return `${diffHours}${i18n.t('period:hours')}`;
  } else if (diffDays < 7) {
    return `${diffDays}${i18n.t('period:days')}`;
  } else if (diffWeeks >= 1 && diffWeeks < 4) {
    return `${diffWeeks} ${i18n.t('period:weeks')}`;
  } else if (date.getFullYear() === now.getFullYear()) {
    return `${date.getDate()} ${i18n.t(
      `period:months_short.${date.getMonth()}`
    )}`;
  } else {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }
};

export const dateToTextShortAll = (date: Date) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const dateDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const diff = now.getTime() - date.getTime();
  const diffSeconds = Math.floor(diff / 1000);
  const diffMinutes = Math.floor(diff / 60000);
  const diffHours = Math.floor(diff / 3600000);
  const diffDays = Math.floor(diff / (24 * 3600000));
  const diffWeeks = Math.floor(diffDays / 7);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  const formatTime = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  if (dateDay.getTime() === today.getTime()) {
    if (diffSeconds < 60) {
      return `${diffSeconds} сек`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} мин`;
    } else {
      return `${diffHours} ч`;
    }
  } else if (diffDays < 7) {
    return `${diffDays} дн`;
  } else if (diffDays < 30) {
    const weeks = diffWeeks;
    return `${weeks} нед`;
  } else if (diffMonths < 12) {
    return `${diffMonths} мес`;
  } else {
    return `${diffYears} год${diffYears > 1 ? 'а' : ''}`;
  }
};

export const formatDateAsNumbers = (date: Date): string => {
  const day = minTwoDigits(date.getDate());
  const month = minTwoDigits(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = minTwoDigits(date.getHours());
  const minutes = minTwoDigits(date.getMinutes());

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const getRegistrationDate = (date: Date) => {
  const day = date.getDate();
  const month = `${i18n.t(`period:months_full.${date.getMonth()}`)}`;
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
