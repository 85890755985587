import React from 'react';

import { useAppMetamask } from 'features/wallets/context/app-metamask-context';

import DisconnectMetamask from './disconnect-metamask';

const DisconnectMetamaskContainer: React.FC = () => {
  const { disconnect } = useAppMetamask();

  return <DisconnectMetamask onClick={disconnect} />;
};

export default DisconnectMetamaskContainer;
