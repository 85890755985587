import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';

import useInfiniteScroll from '../../../hooks/use-infinite-scroll';
import GET_BOOKMARKS_NEWS_LIST, {
  GetBookmarksNewsListQuery,
  GetBookmarksNewsListQueryVars,
} from '../graphql/get-bookmarks-news-list';

const NEWS_LOAD_LIMIT = 10;
const SCROLL_PERCENTAGE_TO_LOAD_NEWS = 0.9;

const useInfiniteBookmarksNews = () => {
  const { t } = useTranslation(['common']);
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const { data, error, fetchMore } = useQuery<
    GetBookmarksNewsListQuery,
    GetBookmarksNewsListQueryVars
  >(GET_BOOKMARKS_NEWS_LIST);

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
      setHasMoreNews(false);
      return;
    }

    if (data?.getBookmarksNewsList.__typename === 'Error') {
      Toast.error(data.getBookmarksNewsList.error);
      setHasMoreNews(false);
    }
  }, [data, error]);

  const loadMoreNews = async () => {
    if (
      data?.getBookmarksNewsList.__typename !== 'GetBookmarksNewsList' ||
      data?.getBookmarksNewsList.news.length === 0 ||
      !hasMoreNews
    ) {
      return;
    }

    const lastBookmarksId =
      data.getBookmarksNewsList.news[data.getBookmarksNewsList.news.length - 1]
        .bookmarksInfo.id;

    try {
      await fetchMore({
        variables: { lastBookmarksId: lastBookmarksId },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          if (fetchMoreResult.getBookmarksNewsList.__typename === 'Error') {
            Toast.error(fetchMoreResult.getBookmarksNewsList.error);
            return prev;
          }

          if (
            fetchMoreResult.getBookmarksNewsList.__typename !==
              'GetBookmarksNewsList' ||
            prev.getBookmarksNewsList.__typename !== 'GetBookmarksNewsList'
          ) {
            Toast.error(t('common:errors:something_went_wrong'));
            return prev;
          }

          if (
            fetchMoreResult.getBookmarksNewsList.news.length < NEWS_LOAD_LIMIT
          ) {
            setHasMoreNews(false);
          }

          return {
            getBookmarksNewsList: {
              __typename: 'GetBookmarksNewsList',
              news: [
                ...prev.getBookmarksNewsList.news,
                ...fetchMoreResult.getBookmarksNewsList.news,
              ],
            },
          };
        },
      });
    } catch (e: unknown) {
      Toast.error(t('common:errors:something_went_wrong'));
    }
  };

  const loading = useInfiniteScroll(
    hasMoreNews,
    SCROLL_PERCENTAGE_TO_LOAD_NEWS,
    loadMoreNews
  );

  return { data, loading };
};
export default useInfiniteBookmarksNews;
