import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useGetModeratedNewsFilters } from './use-get-moderated-news-filters';

export const enum ModeratedNewsListDateSortType {
  'LAST' = 'LAST',
  'ORDER' = 'ORDER',
}

export const enum ModeratedNewsListReviewingStatusesType {
  'WAITING' = 'WAITING',
  'MY' = 'MY',
  'OTHER' = 'OTHER',
  'ALL' = 'ALL',
}

export interface ModeratedNewsListFiltersType {
  sports: string[];
  categories: string[];
  status: ModeratedNewsListReviewingStatusesType;
  sort: ModeratedNewsListDateSortType;
  pageNumber: string;
}

export type updateFiltersType = (
  filterName: keyof ModeratedNewsListFiltersType,
  value: string
) => void;

const DEFAULT_FILTERS = {
  sports: [],
  categories: [],
  status: ModeratedNewsListReviewingStatusesType.ALL,
  sort: ModeratedNewsListDateSortType.LAST,
  pageNumber: '1',
};

const useSetModeratedNewsListFilters = () => {
  const [filters, setFilters] =
    useState<ModeratedNewsListFiltersType>(DEFAULT_FILTERS);
  const router = useRouter();
  const filtersInUrl = useGetModeratedNewsFilters();

  useEffect(() => {
    if (filtersInUrl) {
      setFilters(filtersInUrl);
    }
  }, []);

  const updateFilters: updateFiltersType = (filterName, value) => {
    setFilters((prevFilters) => {
      let updatedValue: string | string[] = value;
      const prevValue = prevFilters[filterName];

      if (Array.isArray(prevValue)) {
        updatedValue = prevValue.includes(value)
          ? prevValue.filter((item) => item !== value)
          : [...prevValue, value];
      }

      const newFilters: ModeratedNewsListFiltersType = {
        ...prevFilters,
        [filterName]: updatedValue,
      };

      const query = { ...router.query };

      Object.keys(newFilters).forEach((key) => {
        const val = newFilters[key as keyof ModeratedNewsListFiltersType];
        query[key] = Array.isArray(val) ? val.join('-') : val;
      });

      router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true }
      );

      return newFilters;
    });
  };

  const resetAllFilters = () => {
    setFilters(DEFAULT_FILTERS);
    const query = { ...router.query };

    Object.keys(DEFAULT_FILTERS).forEach((key) => {
      delete query[key];
    });

    router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
  };

  return { filters, updateFilters, resetAllFilters };
};

export default useSetModeratedNewsListFilters;
