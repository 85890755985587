import ConnectMemtamaskButton from './connect-metamask-button';
import { useAppMetamask } from '../../context/app-metamask-context';

const ConnectMemtamaskButtonContainer: React.FC = () => {
  const { connect } = useAppMetamask();

  return <ConnectMemtamaskButton onClick={connect} />;
};

export default ConnectMemtamaskButtonContainer;
