import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface NewsRejectedReason {
  id: string;
  reason: string;
}

export interface GetNewsRejectedReasonListQuery {
  getNewsRejectedReasonList:
    | {
        __typename: 'GetNewsRejectedReasonList';
        reasons: NewsRejectedReason[];
      }
    | GraphqlErrorType;
}

const GET_NEWS_REJECTED_REASON_LIST = gql`
  query GetNewsRejectedReasonList {
    getNewsRejectedReasonList {
      ... on GetNewsRejectedReasonList {
        reasons {
          id
          reason
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_REJECTED_REASON_LIST;
