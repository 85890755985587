import Link from 'next/link';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InstagramIcon, TelegramIcon, YoutubeIcon } from 'ui/icons';

import css from './footer.module.scss';

interface FooterProps {
  footerUrls: { label: string; url: string }[];
}

const Footer: React.FC<FooterProps> = ({ footerUrls }) => {
  const { t } = useTranslation(['footer']);

  return (
    <footer className={css.Root}>
      <div className={css.Copyright}>{t('footer:copyright')}</div>
      <ul className={css.Menu}>
        {footerUrls.map((item) => (
          <li key={item.url}>
            <Link href={item.url}>{item.label}</Link>
          </li>
        ))}
      </ul>
      <div className={css.Icons}>
        <ul className={css.List}>
          <li>
            <a href="https://youtube.com" target="_blank" rel="noreferrer">
              <YoutubeIcon />
            </a>
          </li>
          <li>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
          </li>
          <li>
            <a href="https://telegram.org" target="_blank" rel="noreferrer">
              <TelegramIcon />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
