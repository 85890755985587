import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export type OnePublishedNewsFooterDataType = Pick<
  OnePublishedNewsDataType,
  | 'id'
  | 'views'
  | 'client'
  | 'isLiked'
  | 'likesCount'
  | 'bookmarksInfo'
  | 'commentsCount'
>;

export interface GetOnePublishedNewsFooterDataQuery {
  getOnePublishedNews:
    | {
        __typename: 'GetOnePublishedNews';
        news: OnePublishedNewsFooterDataType;
      }
    | GraphqlErrorType;
}

export interface GetOnePublishedNewsFooterDataQueryVars {
  id: string;
}

const GET_ONE_PUBLISHED_NEWS_FOOTER_DATA = gql`
  query GetOnePublishedNews($id: ID!) {
    getOnePublishedNews(id: $id) {
      ... on GetOnePublishedNews {
        news {
          id
          client {
            id
            name
            avatar
          }
          bookmarksInfo {
            id
            inBookmarks
          }
          commentsCount
          isLiked
          likesCount

          views
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_ONE_PUBLISHED_NEWS_FOOTER_DATA;
