import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { NewsFormPayloadType } from '../types/news-form-payload-type';
import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export interface UpdatePublishedNewsMutation {
  updatePublishedNews:
    | {
        __typename: 'UpdatePublishedNews';
        news: OnePublishedNewsDataType;
      }
    | GraphqlErrorType;
}

export interface UpdatePublishedNewsMutationVars {
  id: string;
  input: NewsFormPayloadType;
}

const UPDATE_PUBLISHED_NEWS = gql`
  mutation UpdatePublishedNews($id: ID!, $input: UpdateNewsInput!) {
    updatePublishedNews(id: $id, input: $input) {
      ... on UpdatePublishedNews {
        news {
          id
          title
          date
          previewUploadDescription
          previewDescription
          previewImage
          category {
            id
            name
          }
          sport {
            id
            name
            icon
          }
          blocks {
            type
            id
            data {
              text
              caption
              alignment
              level
              items
              style
              stretched
              file {
                url
              }
              html
              url
              serviceName
            }
          }
          client {
            id
            name
            avatar
          }
          bookmarksInfo {
            id
            inBookmarks
          }
          commentsCount
          likesCount
          isLiked
          views
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPDATE_PUBLISHED_NEWS;
