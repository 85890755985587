import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface TransferMoneyMutation {
  transferMoney:
    | {
        __typename: 'TransferMoney';
        client: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface TransferMoneyMutationVars {
  input: {
    from: string;
    to: string;
    amount: string;
  };
}

const TRANSFER_MONEY = gql`
  mutation TransferMoney($input: TransferMoneyInput!) {
    transferMoney(input: $input) {
      __typename
      ... on TransferMoney {
        client {
          id
        }
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default TRANSFER_MONEY;
