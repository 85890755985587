import React, { useState } from 'react';

import webApp from 'application/web-app';

import ConnectAddress from './connect-address';
import { useAppMetamask } from '../../context/app-metamask-context';
import useAddWalletAddressClient from '../../hooks/use-add-wallet-address-client';
import useUpdateWalletAddressConnectionCode from '../../hooks/use-update-wallet-address-connection-code';

const ConnectAddressContainer: React.FC = () => {
  const { account, sign } = useAppMetamask();
  const addWalletAddress = useAddWalletAddressClient();
  const updateWalletAddressConnectionCode =
    useUpdateWalletAddressConnectionCode();

  const [processing, setProcessing] = useState(false);

  return (
    <ConnectAddress
      onClick={async () => {
        setProcessing(true);

        try {
          const code = await updateWalletAddressConnectionCode(account);

          const signValue = await sign(code || '');

          await addWalletAddress(account, signValue);
        } catch (e: unknown) {
          webApp.log.exception(e);
        } finally {
          setProcessing(false);
        }
      }}
      disabled={processing}
    />
  );
};

export default ConnectAddressContainer;
