import React, { Ref, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Crop, ReactCrop } from 'react-image-crop';

import { Button, Modal, ModalFooter } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './upload-client-avatar-modal.module.scss';
import { ASPECT_RATIO, MIN_DIMENSION } from '../upload-client-avatar';

interface UploadClientAvatarModalProps {
  imgUrl: string;
  crop?: Crop;
  isModalOpen: boolean;
  croppedImageFile: File | null;
  imgRef: Ref<HTMLImageElement>;
  cropImage: () => void;
  uploadFile: () => void;
  uploadAvatar: () => void;
  clearReactCrop: () => void;
  setCrop: (crop: Crop) => void;
  onImageLoad: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
}

const UploadClientAvatarModal: React.FC<UploadClientAvatarModalProps> = ({
  imgUrl,
  crop,
  croppedImageFile,
  isModalOpen,
  imgRef,
  setCrop,
  cropImage,
  uploadAvatar,
  onImageLoad,
  clearReactCrop,
  uploadFile,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      width={700}
      title={t('account:labels:choose_new_avatar')}
      isOpen={isModalOpen}
      onClose={clearReactCrop}
    >
      <div className={css.ModalContainer}>
        {imgUrl ? (
          <ReactCrop
            crop={crop}
            keepSelection
            minWidth={MIN_DIMENSION}
            aspect={ASPECT_RATIO}
            onComplete={cropImage}
            onChange={(pixelCrop, percentCrop) => {
              setCrop(percentCrop);
            }}
          >
            <img
              ref={imgRef}
              src={imgUrl}
              onLoad={onImageLoad}
              className={css.Avatar}
              alt={t('common:alt:u_avatar')}
            />
          </ReactCrop>
        ) : (
          <p className={css.UploadInfo}>
            <Trans
              i18nKey="account:labels:upload_avatar_info"
              components={{
                1: <span></span>,
                2: <br />,
              }}
            />
          </p>
        )}
      </div>

      <ModalFooter>
        <Flex gap={GridGap.x1}>
          <Button color="invert" onClick={uploadFile}>
            <p className={css.Button}> {t('common:actions:upload')}</p>
          </Button>

          <Button onClick={uploadAvatar} disabled={!croppedImageFile}>
            <p className={css.Button}>{t('common:actions:save')}</p>
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default UploadClientAvatarModal;
