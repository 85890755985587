import React, { useEffect, useState } from 'react';

import { SortAscIcon, SortDescIcon, SortIcon } from 'ui/icons';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import css from './sorting-button.module.scss';

interface SortingButtonProps {
  children: React.ReactNode;
  onChange?: (state: SortDirection) => void;
  align?: 'right' | 'left';
  defaultDirection?: SortDirection;
  active?: boolean;
  className?: string;
}

export const SortingButton: React.FC<SortingButtonProps> = ({
  children,
  onChange,
  align = 'left',
  active,
  defaultDirection = SortDirection.NONE,
  className,
}) => {
  const directions = [
    SortDirection.NONE,
    SortDirection.ASC,
    SortDirection.DESC,
  ];

  const [direction, setDirection] = useState(defaultDirection);
  const [counter, setCounter] = useState(
    directions.indexOf(defaultDirection) + 1
  );

  const classes = [css.sorting_button, className];

  if (direction !== SortDirection.NONE) {
    classes.push(css.active);
  }

  const onSort = () => {
    setCounter((oldCounter) => oldCounter + 1);
    const newDirection = directions[counter % 3];
    setDirection(newDirection);
    onChange?.(newDirection);
  };

  useEffect(() => {
    if (!active) {
      setDirection(SortDirection.NONE);
      setCounter(directions.indexOf(SortDirection.NONE) + 1);
    }
  }, [active]);

  return (
    <span className={classes.join(' ')} onClick={onSort}>
      <span>{children}</span>
      {SortDirection.ASC === direction && <SortAscIcon />}
      {SortDirection.DESC === direction && <SortDescIcon />}
      {SortDirection.NONE === direction && <SortIcon />}
    </span>
  );
};
