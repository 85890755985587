import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface DeleteClientAvatarMutation {
  deleteClientAvatar:
    | {
        __typename: 'DeleteClientAvatar';
        clientId: string;
      }
    | GraphqlErrorType;
}

const DELETE_CLIENT_AVATAR = gql`
  mutation DeleteClientAvatar {
    deleteClientAvatar {
      __typename
      ... on DeleteClientAvatar {
        clientId
      }
      ... on Error {
        error
      }
    }
  }
`;

export default DELETE_CLIENT_AVATAR;
