import React from 'react';

import { Textarea } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { Radio } from 'ui/radio/radio';
import { RadioGroup } from 'ui/radio/radio-group';

import { NewsRejectedReason } from '../../graphql/get-news-rejected-reason-list';

import { REASON_OTHER_ID } from './index';

interface NewsRejectedReasonListProps {
  reasonsList: NewsRejectedReason[];
  reasonId: string;
  otherReasonText: string;
  setOtherReasonText: (text: string) => void;
  setReasonId: (reasonId: string) => void;
}

const NewsRejectedReasonList: React.FC<NewsRejectedReasonListProps> = ({
  reasonsList,
  reasonId,
  otherReasonText,
  setOtherReasonText,
  setReasonId,
}) => {
  return (
    <RadioGroup onChange={setReasonId}>
      {reasonsList.map((r) =>
        r.id === REASON_OTHER_ID ? (
          <Flex key={r.id} flexDirection="column" gap={GridGap.x2}>
            <Radio value={r.id} checked={r.id === reasonId}>
              {r.reason}:
            </Radio>
            <Textarea value={otherReasonText} onChange={setOtherReasonText} />
          </Flex>
        ) : (
          <Radio value={r.id} checked={reasonId === r.id} key={r.id}>
            {r.reason}
          </Radio>
        )
      )}
    </RadioGroup>
  );
};

export default NewsRejectedReasonList;
