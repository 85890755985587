import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface GetMySocialRatingQuery {
  getMySocialRating:
    | {
        __typename: 'GetMySocialRating';
        rating: number;
      }
    | GraphqlErrorType;
}

export interface GetMySocialRatingQueryVars {}

const GET_MY_SOCIAL_RATING = gql`
  query GetMySocialRating {
    getMySocialRating {
      __typename
      ... on GetMySocialRating {
        rating
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MY_SOCIAL_RATING;
