import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { StickyColumn } from 'features/sticky-column/sticky-column';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { SortingButtonLight } from 'ui/sorting-button-light';
import { Text } from 'ui/v2/typography/text';

import css from './client-pari-list.module.scss';
import { ClientPariListTab, PariSortBy } from '../../pari.types';

interface Props {
  sort: { key: PariSortBy; sort: SortDirection };
  setSort: (args: { key: PariSortBy; sort: SortDirection }) => void;
  currentTab: ClientPariListTab;
  columnsGrid: string;
  columnsGridTablet: string;
  columnsGridMobile: string;
}

const PariListTableHeader = ({
  setSort,
  sort,
  currentTab,
  columnsGrid,
  columnsGridTablet,
  columnsGridMobile,
}: Props) => {
  const { t } = useTranslation(['events', 'news']);

  return (
    <ResponsiveGrid
      className={css.Table}
      alignItems="center"
      gap={GridGap.x2}
      gridTemplateColumns={columnsGrid}
      tablet={{ gridTemplateColumns: columnsGridTablet }}
      mobile={{ gridTemplateColumns: columnsGridMobile }}
      gridAutoRows="40px"
    >
      <SortingButtonLight
        className={cx(css.Sort, css.Date)}
        activeDirection={SortDirection.DESC}
        active={sort.key === 'date'}
        onChange={(direction) => {
          setSort({ key: 'date', sort: direction });
        }}
      >
        {t('period:date')}
      </SortingButtonLight>
      <Text type="headline">{t('events:event')}</Text>
      <SortingButtonLight
        className={css.Sort}
        activeDirection={SortDirection.DESC}
        active={sort.key === 'amount'}
        onChange={(direction) => {
          setSort({
            key: 'amount',
            sort: direction,
          });
        }}
      >
        {t('bets:amount')} vts
      </SortingButtonLight>
      <Text type="headline" textAlign="center">
        {t('bets:coeff')}
      </Text>
      {currentTab === ClientPariListTab.ACTIVE ? (
        <StickyColumn>
          <Text type="headline" textAlign="center" style={{ textWrap: 'wrap' }}>
            {t('bets:possible_winnings')}
          </Text>
        </StickyColumn>
      ) : (
        <>
          <Text type="headline" textAlign="center">
            {t('bets:winning')}
          </Text>
          <StickyColumn>
            <Text type="headline" textAlign="center">
              {t('news:labels:status')}
            </Text>
          </StickyColumn>
        </>
      )}
    </ResponsiveGrid>
  );
};

export default PariListTableHeader;
