import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'application/context/user-provider';
import { Toast } from 'ui';

import CreateNewsModalFooter from './create-news-modal-footer';
import { BalancePageTabType } from '../../../cabinet/pages/balance-page';
import usePublishNews from '../../hooks/use-publish-news';
import { NewsSaveStatusType } from '../../types/news-save-status-type';

interface CreateNewsModalFooterContainerProps {
  newsId: string | null;
  newsSaveStatus: NewsSaveStatusType;
  closeModal: () => void;
}

const CreateNewsModalFooterContainer: React.FC<
  CreateNewsModalFooterContainerProps
> = ({ newsId, newsSaveStatus, closeModal }) => {
  const { t } = useTranslation(['news']);
  const publishNewsMutation = usePublishNews();
  const { user } = useUser();
  const router = useRouter();

  const PUBLISH_ARTICLE_DISCLAIMER_USER = t(
    'news:actions:publish_article_disclaimer_user'
  );
  const PUBLISH_ARTICLE_DISCLAIMER_EDITOR = t(
    'news:actions:publish_article_disclaimer_editor'
  );
  const ARTICLE_PUBLISHED_EDITOR = t('news:successes:article_published');
  const ARTICLE_PUBLISHED_USER = t(
    'news:successes:send_article_for_moderation'
  );

  const publishNews = async () => {
    if (!newsId) return;

    const confirmPublish = window.confirm(
      user?.editor
        ? PUBLISH_ARTICLE_DISCLAIMER_EDITOR
        : PUBLISH_ARTICLE_DISCLAIMER_USER
    );

    if (!confirmPublish) return;

    const isRequestSuccess = await publishNewsMutation(newsId);
    if (isRequestSuccess) {
      Toast.success(
        user?.editor ? ARTICLE_PUBLISHED_EDITOR : ARTICLE_PUBLISHED_USER
      );
      router.push(
        `/user/balance?tab=${BalancePageTabType.UNPUBLISHED_NEWS_LIST}`
      );
      closeModal();
    }
  };

  const openNewsPreview = () => {
    router.push(`/unpublished-news/${newsId}`);
  };

  return (
    <CreateNewsModalFooter
      openNewsPreview={openNewsPreview}
      publishNews={publishNews}
      buttonDisabled={!newsId}
      newsSaveStatus={newsSaveStatus}
    />
  );
};

export default CreateNewsModalFooterContainer;
