import { StickyColumn } from 'features/sticky-column/sticky-column';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { SortingButton } from 'ui/sorting-button';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';
import { Text } from 'ui/v2/typography/text';

import css from './live-orders-list.module.scss';

interface Props {
  sort: SortDirection;
  setSort: (arg: SortDirection) => void;
  gridTemplateColumns: string;
  gridTemplateColumnsTablet: string;
  gridTemplateColumnsMobile: string;
}

const OrderListTableHeader = ({
  sort,
  setSort,
  gridTemplateColumns,
  gridTemplateColumnsTablet,
  gridTemplateColumnsMobile,
}: Props) => {
  return (
    <ResponsiveGrid
      alignItems="center"
      gap={GridGap.x2}
      gridTemplateColumns={gridTemplateColumns}
      mobile={{ gridTemplateColumns: gridTemplateColumnsMobile }}
      tablet={{ gridTemplateColumns: gridTemplateColumnsTablet }}
      gridAutoRows="40px"
      className={css.Table}
    >
      <SortingButton
        defaultDirection={
          sort !== SortDirection.NONE ? sort : SortDirection.NONE
        }
        active={sort !== SortDirection.NONE}
        onChange={setSort}
        className={css.Sort}
      >
        Date
      </SortingButton>
      <Text type="headline">Event name</Text>
      <Text type="headline">Token name</Text>
      <Text type="headline" textAlign="center">
        Type
      </Text>
      <Text type="headline" textAlign="center">
        Id
      </Text>
      <Text type="headline" textAlign="center">
        Quantity
      </Text>
      <Text type="headline" textAlign="center" className={css.Wrap}>
        Order price vts
      </Text>
      <Text type="headline" textAlign="center">
        Executed
      </Text>
      <Text type="headline" textAlign="center" className={css.Wrap}>
        Executed price vts
      </Text>
      <Text type="headline" textAlign="center" className={css.Wrap}>
        Volume vts
      </Text>
      <StickyColumn>
        <Text type="headline" textAlign="center">
          Status
        </Text>
      </StickyColumn>
    </ResponsiveGrid>
  );
};

export default OrderListTableHeader;
