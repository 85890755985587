import { useTranslation } from 'react-i18next';

import { Button } from 'ui';

import css from './connect-metamask-button.module.scss';
import MetamaskIcon from './metamask.svg';

interface ConnectMemtamaskButtonProps {
  onClick: () => void;
}

const ConnectMemtamaskButton: React.FC<ConnectMemtamaskButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation(['wallets']);

  return (
    <Button
      onClick={onClick}
      className={css.Button}
      icon={<MetamaskIcon width={30} height={30} />}
      outline
    >
      {t('connect_metamask')}
    </Button>
  );
};

export default ConnectMemtamaskButton;
