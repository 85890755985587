import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { errorNotification } from 'application/notification';

import GET_CLIENT_COUNTS, {
  GetClientCountsQuery,
} from '../graphql/get-сlient-сounts';

const useClientCounts = () => {
  const { data, error } = useQuery<GetClientCountsQuery>(GET_CLIENT_COUNTS);

  useEffect(() => {
    if (error) {
      errorNotification(error.message);
    }

    if (data?.getClientCounts.__typename === 'Error') {
      errorNotification(data.getClientCounts.error);
    }
  }, [data, error]);

  if (data?.getClientCounts.__typename !== 'GetClientCounts') return null;

  return data?.getClientCounts.counts;
};

export default useClientCounts;
