import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { verifyApolloQueryResponse } from 'application/graphql/utils';
import { removeTypename } from 'application/utils/remove-typename';
import { Toast } from 'ui';
import { EditorBlocksType } from 'ui/editor';

import GET_ONE_UNPUBLISHED_NEWS, {
  GetOneUnpublishedNewsQuery,
  GetOneUnpublishedNewsQueryVars,
} from '../graphql/get-one-unpublished-news';
import { NewsFormDataType } from '../types/news-form-data-type';

const useEditUnpublishedNews = (newsId?: string) => {
  const {
    data,
    loading: newsFormDataLoading,
    error,
  } = useQuery<GetOneUnpublishedNewsQuery, GetOneUnpublishedNewsQueryVars>(
    GET_ONE_UNPUBLISHED_NEWS,
    {
      skip: !newsId,
      variables: newsId ? { id: newsId } : undefined,
      fetchPolicy: 'network-only',
    }
  );

  const [newsFormData, setNewsFormData] = useState<NewsFormDataType>({
    id: null,
    title: '',
    previewDescription: '',
    previewImage: '',
    previewVideo: '',
    previewUploadDescription: '',
    blocks: [],
    categoryId: null,
    sportId: null,
  });

  useEffect(() => {
    if (error) {
      verifyApolloQueryResponse(data, error);
      return;
    }
    if (data?.getOneUnpublishedNews.__typename === 'Error') {
      Toast.error(data.getOneUnpublishedNews.error);
      return;
    }

    if (
      data &&
      data.getOneUnpublishedNews.__typename === 'GetOneUnpublishedNews'
    ) {
      const {
        news: {
          id,
          sport: { id: sportId },
          category: { id: categoryId },
          title,
          previewDescription,
          previewImage,
          previewVideo,
          previewUploadDescription,
          blocks,
        },
      } = data.getOneUnpublishedNews;

      setNewsFormData({
        id,
        sportId,
        categoryId,
        title,
        previewDescription,
        previewImage,
        previewVideo,
        previewUploadDescription,
        blocks: removeTypename<EditorBlocksType>(blocks),
      });
    }
  }, [data, error]);

  const updateNewsFormData = <T extends keyof NewsFormDataType>(
    field: T,
    value: NewsFormDataType[T],
    saveNewsData?: (newsFormData: NewsFormDataType) => void
  ) => {
    setNewsFormData((prevState) => {
      const args = {
        ...prevState,
        [field]: value,
      };

      if (field === 'previewVideo') {
        args.previewImage = '';
      }

      if (field === 'previewImage') {
        args.previewVideo = '';
      }

      saveNewsData?.(args);

      return args;
    });
  };

  return {
    newsFormData,
    newsFormDataLoading,
    updateNewsFormData,
  };
};

export default useEditUnpublishedNews;
