import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface PublishNewsMutation {
  publishNews:
    | {
        __typename: 'PublishNews';
        news: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface PublishNewsMutationVars {
  id: string;
}

const PUBLISH_NEWS = gql`
  mutation PublishNews($id: ID!) {
    publishNews(id: $id) {
      ... on PublishNews {
        news {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default PUBLISH_NEWS;
