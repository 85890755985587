import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export type BookmarksNews = Omit<OnePublishedNewsDataType, 'blocks'>;

export interface GetBookmarksNewsListQuery {
  getBookmarksNewsList:
    | {
        __typename: 'GetBookmarksNewsList';
        news: BookmarksNews[];
      }
    | GraphqlErrorType;
}

export interface GetBookmarksNewsListQueryVars {
  lastBookmarksId?: string;
}

const GET_BOOKMARKS_NEWS_LIST = gql`
  query GetBookmarksNewsList($lastBookmarksId: ID) {
    getBookmarksNewsList(lastBookmarksId: $lastBookmarksId) {
      ... on GetBookmarksNewsList {
        news {
          id
          title
          previewDescription
          previewImage
          date
          sport {
            id
            name
            icon
          }
          client {
            id
            name
            avatar
          }
          bookmarksInfo {
            id
            inBookmarks
          }
          commentsCount
          likesCount
          isLiked
          views
          category {
            id
            name
          }
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_BOOKMARKS_NEWS_LIST;
