import React from 'react';

import { Select } from 'ui';

import css from './news-sport-select.module.scss';
import { EventSportType } from '../../graphql/get-event-sports-list';

interface NewsSportSelectProps {
  sport: string;
  sports: EventSportType[];
  sportChange: (sport: string) => void;
}

const NewsSportSelect: React.FC<NewsSportSelectProps> = ({
  sport,
  sports,
  sportChange,
}) => {
  return (
    <div className={css.Select}>
      <Select
        value={sport}
        items={sports.map((sport) => ({
          value: sport.id,
          label: sport.name,
          icon: sport.icon,
        }))}
        onChange={sportChange}
      />
    </div>
  );
};

export default NewsSportSelect;
