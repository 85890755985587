import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { errorNotification } from 'application/notification';
import { getCorrectSortDirection } from 'application/utils/getCorrectSortDirection';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import GET_STATS_ORDERS, {
  GetStatsLiveOrdersQuery,
  GetStatsLiveOrdersQueryVars,
} from '../graphql/get-user-statistic-live-orders';

type Order = 'market' | 'limit';

export enum LiveOrdersTabs {
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export const useGetLiveOrders = () => {
  const { t } = useTranslation(['bets', 'events']);

  const [orderType, setOrderType] = useState<Order>('market');
  const [tab, setTab] = useState<LiveOrdersTabs>(LiveOrdersTabs.ACTIVE);
  const [sort, setSort] = useState<SortDirection>(SortDirection.NONE);
  const [page, setPage] = useState(1);

  const tabs = [
    {
      value: LiveOrdersTabs.ACTIVE,
      label: t('events:active'),
    },
    {
      value: LiveOrdersTabs.COMPLETED,
      label: t('events:completed_s'),
    },
  ];

  const switchItems: { value: Order; label: string }[] = [
    {
      value: 'market',
      label: t('bets:market'),
    },
    {
      value: 'limit',
      label: t('bets:limit'),
    },
  ];

  const { data, loading, error } = useQuery<
    GetStatsLiveOrdersQuery,
    GetStatsLiveOrdersQueryVars
  >(GET_STATS_ORDERS, {
    variables: {
      orderType,
      sort: {
        isActive: tab === LiveOrdersTabs.ACTIVE,
        page,
        sortDirection: getCorrectSortDirection(sort),
      },
    },
  });

  useEffect(() => {
    if (error) {
      errorNotification(error.message);
    }

    if (data?.getStatsLiveOrders.__typename === 'Error') {
      errorNotification(data.getStatsLiveOrders.error);
    }
  }, [data, error]);

  return {
    data,
    loading,
    orderType,
    setOrderType,
    switchItems,
    tabs: { items: tabs, current: tab, setTab },
    pagination: { page, setPage },
    sort,
    setSort,
  };
};
