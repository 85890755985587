import React from 'react';

import css from './headline.module.scss';

type HeadlineTypes = 'small' | 'medium' | 'large';

interface HeadlineProps {
  children: React.ReactNode;
  type?: HeadlineTypes;
}

export const Headline: React.FC<HeadlineProps> = ({
  children,
  type = 'small',
}) => {
  const typeMap: Record<HeadlineTypes, any> = {
    small: css.small,
    medium: css.medium,
    large: css.large,
  };

  return (
    <span className={[css.headline, typeMap[type]].join(' ')}>{children}</span>
  );
};
