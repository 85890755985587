import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_NEWS_CATEGORIES, {
  GetNewsCategoriesQuery,
} from '../graphql/get-news-categories';

const useGetNewsCategories = () => {
  const { data, error } = useQuery<GetNewsCategoriesQuery>(GET_NEWS_CATEGORIES);

  useEffect(() => {
    if (error) {
      Toast.error(error.message);
    }

    if (data?.getNewsCategories.__typename === 'Error') {
      Toast.error(data.getNewsCategories.error);
    }
  }, [data, error]);

  if (data?.getNewsCategories.__typename !== 'GetNewsCategories') {
    return null;
  }

  return data?.getNewsCategories.categories;
};

export default useGetNewsCategories;
