import cx from 'classnames';
import Link from 'next/link';

import { formatDatetimeToFull } from 'application/utils/date';
import SuccessIcon from 'assets/icons/success_m.svg';
import RejectIcon from 'assets/icons/x_m.svg';
import { StickyColumn } from 'features/sticky-column/sticky-column';
import { useMediaQuery } from 'hooks/use-media-query.hook';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Space } from 'ui/space/space';
import { Text } from 'ui/v2/typography/text';

import css from './client-pari-list.module.scss';
import { MyPariModel } from '../../graphql/get-my-pari-info';
import { ClientPariListTab } from '../../pari.types';

interface Props {
  pariList: MyPariModel[];
  currentTab: ClientPariListTab;
  columnsGrid: string;
  columnsGridTablet: string;
  columnsGridMobile: string;
}

const PariListTableContent = ({
  pariList,
  currentTab,
  columnsGrid,
  columnsGridTablet,
  columnsGridMobile,
}: Props) => {
  const isMobile = useMediaQuery('(max-width: 430px)');

  return (
    <>
      {pariList.map((item, index) => (
        <ResponsiveGrid
          className={cx(css.Table, css.Border_top, {
            [css.Gray_back]: index % 2 === 0,
          })}
          alignItems="center"
          gap={GridGap.x2}
          gridTemplateColumns={columnsGrid}
          gridAutoRows="minmax(74px, 1fr)"
          tablet={{ gridTemplateColumns: columnsGridTablet }}
          mobile={{ gridTemplateColumns: columnsGridMobile }}
          key={index}
        >
          <Text
            type="paragraph"
            mobile={{ fontSize: '12px' }}
            tablet={{ fontSize: '14px' }}
          >
            <span style={{ whiteSpace: 'wrap' }}>
              {currentTab !== ClientPariListTab.ACTIVE
                ? formatDatetimeToFull(new Date(item.finishedAt), isMobile)
                : formatDatetimeToFull(new Date(item.date), isMobile)}
            </span>
          </Text>
          <ResponsiveGrid
            gridTemplateColumns="34px 1fr"
            gap={GridGap.x2}
            className={css.Event}
            mobile={{ gap: GridGap.x1 }}
          >
            <img
              src={item.tournament.icon}
              width={34}
              height={34}
              alt={item.tournament.name}
            />
            <Flex flexDirection="column" style={{ whiteSpace: 'wrap' }}>
              <Link href={`/tournament/${item.tournament.id}`}>
                {`${item.result.name} - ${item.outcome.name}`}
              </Link>
              <Space size={4} />
              <Text type="caption2" style={{ whiteSpace: 'wrap' }}>
                {item.tournament.name}: {item.event?.name}
              </Text>
            </Flex>
          </ResponsiveGrid>
          <Text
            type="paragraph"
            mobile={{ fontSize: '12px' }}
            tablet={{ fontSize: '14px' }}
            textAlign="center"
          >
            {item.amount}
          </Text>
          <Text
            type="paragraph"
            mobile={{ fontSize: '12px' }}
            tablet={{ fontSize: '14px' }}
            textAlign="center"
            color={
              item.outcome.win === false ? 'black-primary' : 'blue-primary'
            }
          >
            {item.outcome.win === false
              ? '-'
              : Math.round((item.winAmount / item.amount) * 100) / 100}
          </Text>
          {currentTab === ClientPariListTab.ACTIVE ? (
            <StickyColumn>
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
                textAlign="center"
              >
                {item.winAmount === 0 ? '-' : `${item.winAmount}`}
              </Text>
            </StickyColumn>
          ) : (
            <>
              <Text
                type="paragraph"
                mobile={{ fontSize: '12px' }}
                tablet={{ fontSize: '14px' }}
                textAlign="center"
              >
                {item.winAmount === 0 ? '-' : `${item.winAmount}`}
              </Text>
              <StickyColumn>
                <Text
                  type="paragraph"
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  textAlign="center"
                >
                  {item.outcome.win === null ? (
                    '-'
                  ) : item.outcome.win === true ? (
                    <SuccessIcon />
                  ) : (
                    <RejectIcon />
                  )}
                </Text>
              </StickyColumn>
            </>
          )}
        </ResponsiveGrid>
      ))}
    </>
  );
};

export default PariListTableContent;
