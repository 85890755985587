import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import GET_MY_CLIENT from 'application/graphql/get-my-client';
import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import DELETE_CLIENT_AVATAR, {
  DeleteClientAvatarMutation,
} from '../graphql/delete-сlient-avatar';

const useDeleteClientAvatar = () => {
  const { t } = useTranslation(['avatar']);
  const [deleteAvatarMutation] = useMutation<DeleteClientAvatarMutation>(
    DELETE_CLIENT_AVATAR,
    { refetchQueries: [GET_MY_CLIENT] }
  );

  return async () => {
    try {
      const { data, errors } = await deleteAvatarMutation();

      const isDeleteAvatarSuccess = verifyApolloMutationResponse(data, errors);
      if (!isDeleteAvatarSuccess) return;

      if (data?.deleteClientAvatar.__typename === 'Error') {
        Toast.error(data.deleteClientAvatar.error);
        return;
      }

      if (data?.deleteClientAvatar.__typename === 'DeleteClientAvatar') {
        Toast.info(t('account:successes:avatar_delete_success'));
        return data.deleteClientAvatar.clientId;
      }
    } catch (e: unknown) {
      Toast.error(t('account:errors:avatar_delete_error'));
    }
  };
};

export default useDeleteClientAvatar;
