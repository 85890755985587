import { useMutation } from '@apollo/client';

import GET_MY_CLIENT from '../application/graphql/get-my-client';
import GET_MY_CLIENT_TRANSACTIONS from '../features/cabinet/graphql/get-my-client-transactions';
import TRANSFER_MONEY, {
  TransferMoneyMutation,
  TransferMoneyMutationVars,
} from '../features/cabinet/graphql/transfer-money';

export const useTransferMoneyHook = () => {
  const [transferMoney, { loading }] = useMutation<
    TransferMoneyMutation,
    TransferMoneyMutationVars
  >(TRANSFER_MONEY, {
    refetchQueries: [GET_MY_CLIENT, GET_MY_CLIENT_TRANSACTIONS],
  });

  return {
    transferMoney,
    loading,
  };
};
