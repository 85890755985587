import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';

import GET_MY_WALLET_ADDRESSES from '../graphql/get-my-wallet-addresses';
import UPDATE_WALLET_ADDRESS_CONNECTION_CODE, {
  UpdateWalletAddressConnectionCodeMutation,
  UpdateWalletAddressConnectionCodeMutationVars,
} from '../graphql/update-wallet-address-connection-code';

const useUpdateWalletAddressConnectionCode = () => {
  const { t } = useTranslation('common');

  const [updateCode] = useMutation<
    UpdateWalletAddressConnectionCodeMutation,
    UpdateWalletAddressConnectionCodeMutationVars
  >(UPDATE_WALLET_ADDRESS_CONNECTION_CODE);

  const updateCodeCallback = useCallback(
    async (address: string) => {
      const { data, errors } = await updateCode({
        variables: {
          address,
        },
        refetchQueries: [GET_MY_WALLET_ADDRESSES],
      });

      if (errors) {
        Toast.error(errors.map((item) => item.message).join(', '));
        return;
      }

      if (!data) {
        Toast.error(t('common:errors:undefined_error'));
        return;
      }

      if (data.updateWalletAddressConnectionCode.__typename == 'Error') {
        Toast.error(data.updateWalletAddressConnectionCode.error);
        return;
      }

      return data.updateWalletAddressConnectionCode.code;
    },
    [t, updateCode]
  );

  return updateCodeCallback;
};

export default useUpdateWalletAddressConnectionCode;
