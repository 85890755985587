import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { errorNotification } from 'application/notification';
import { getCorrectSortDirection } from 'application/utils/getCorrectSortDirection';
import { isGraphqlError } from 'features/main/components/graphql-error-guard/graphql-error-guard';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import GET_PARI_STATS_EVENTS, {
  GetPariStatsByEventsQuery,
  GetPariStatsByEventsQueryVars,
  PAGE_LIMIT_PARI,
} from '../graphql/get-user-statistic-pari-events';

export const useGetStatisticLive = () => {
  const [direction, setDirection] = useState<SortDirection>(SortDirection.NONE);

  const { data, loading, fetchMore, error } = useQuery<
    GetPariStatsByEventsQuery,
    GetPariStatsByEventsQueryVars
  >(GET_PARI_STATS_EVENTS, {
    variables: {
      nav: { limit: PAGE_LIMIT_PARI, offset: 0 },
      sort: {
        eventStartDate: getCorrectSortDirection(direction),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const onGetNextPage = (limit: number) => {
    if (data?.getPariStatsByEvents.__typename !== 'GetPariStatsByEvents')
      return;
    fetchMore({
      variables: {
        nav: {
          limit: PAGE_LIMIT_PARI,
          offset: data.getPariStatsByEvents.nav.offset + limit,
        },
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (isGraphqlError(previousResult.getPariStatsByEvents))
          return previousResult;
        if (isGraphqlError(fetchMoreResult.getPariStatsByEvents))
          return previousResult;

        fetchMoreResult.getPariStatsByEvents.events = [
          ...previousResult.getPariStatsByEvents.events,
          ...fetchMoreResult.getPariStatsByEvents.events,
        ];

        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    if (error) {
      errorNotification(error.message);
    }

    if (data?.getPariStatsByEvents.__typename === 'Error') {
      errorNotification(data.getPariStatsByEvents.error);
    }
  }, [data, error]);

  return { data, loading, onGetNextPage, sort: { direction, setDirection } };
};
