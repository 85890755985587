import React, { RefObject, useEffect, useState } from 'react';

import { debounce } from 'application/utils/debounce';

import NewsPreviewImage from './news-preview-image';

interface NewsPreviewImageContainerProps {
  previewImage: string;
  containerRef: RefObject<HTMLDivElement>;
  caption: string;
}

const NewsPreviewImageContainer: React.FC<NewsPreviewImageContainerProps> = ({
  previewImage,
  containerRef,
  caption,
}) => {
  const [containerWidth, setContainerWidth] = useState<string>('');
  const [addBackground, setAddBackground] = useState<null | boolean>(null);

  const updateWidth = () => {
    setContainerWidth(String(containerRef?.current?.offsetWidth));
  };

  const debouncedUpdateWidth = debounce(() => {
    setContainerWidth(String(containerRef?.current?.offsetWidth));
  }, 100);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', debouncedUpdateWidth);

    return () => {
      window.removeEventListener('resize', debouncedUpdateWidth);
    };
  }, []);

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      if (img.height > img.width) {
        setAddBackground(true);
      } else {
        setAddBackground(false);
      }
    };

    img.src = previewImage;
  }, [previewImage]);

  if (!previewImage || !containerRef || addBackground === null) return <></>;

  return (
    <NewsPreviewImage
      imageUrl={previewImage}
      maxHeight={containerWidth}
      addBackground={addBackground}
      caption={caption}
    />
  );
};

export default NewsPreviewImageContainer;
