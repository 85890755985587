import { useEffect, useState } from 'react';

import { removeTypename } from 'application/utils/remove-typename';
import { EditorBlocksType } from 'ui/editor';

import { NewsPreviewDataType } from '../types/new-preview-data-types';
import { NewsFormDataType } from '../types/news-form-data-type';

export interface EditingNewsDataType extends NewsPreviewDataType {
  id: null | string;
  blocks: EditorBlocksType;
}

const useEditNews = (data: EditingNewsDataType | null) => {
  const [isInitialDataReady, setInitialDataReady] = useState(false);
  const [isNewsDataModified, setNewsDataModified] = useState(false);
  const [newsFormData, setNewsFormData] = useState<NewsFormDataType>({
    id: null,
    title: '',
    previewDescription: '',
    previewImage: '',
    previewVideo: '',
    blocks: [],
    categoryId: null,
    sportId: null,
    previewUploadDescription: '',
  });

  useEffect(() => {
    if (data) {
      setNewsFormData({
        id: data.id,
        sportId: data.sport.id,
        categoryId: data.category.id,
        title: data.title,
        previewDescription: data.previewDescription,
        previewImage: data.previewImage,
        previewVideo: data.previewVideo,
        blocks: removeTypename<EditorBlocksType>(data.blocks),
        previewUploadDescription: data.previewUploadDescription || '',
      });
      setInitialDataReady(true);
    }
  }, [data]);

  const updateNewsFormData = <T extends keyof NewsFormDataType>(
    field: T,
    value: NewsFormDataType[T]
  ) => {
    if (!data) return;

    if (!isNewsDataModified) {
      let isNewsValueNew;

      switch (field) {
        case 'sportId':
          isNewsValueNew = data.sport.id !== value;
          break;
        case 'categoryId':
          isNewsValueNew = data.category.id !== value;
          break;
        default:
          isNewsValueNew = data[field as keyof EditingNewsDataType] !== value;
      }

      isNewsValueNew && setNewsDataModified(true);
    }

    setNewsFormData((prevState) => {
      const newArgs = { ...prevState, [field]: value };

      if (field === 'previewVideo') {
        newArgs.previewImage = '';
      }

      if (field === 'previewImage') {
        newArgs.previewVideo = '';
      }

      return newArgs;
    });
  };

  return {
    isInitialDataReady,
    newsFormData,
    updateNewsFormData,
    isNewsDataModified,
    setNewsFormData,
  };
};

export default useEditNews;
