import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type EventSportType = {
  name: string;
  icon: string;
  id: string;
  alias: string;
};

export interface GetEventSportsListQuery {
  getEventSportsList:
    | {
        __typename: 'GetEventSportsList';
        sports: EventSportType[];
      }
    | GraphqlErrorType;
}

const GET_EVENT_SPORTS_LIST = gql`
  query GetEventSportsList {
    getEventSportsList {
      __typename
      ... on GetEventSportsList {
        sports {
          name
          icon
          id
          alias
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_EVENT_SPORTS_LIST;
