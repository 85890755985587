const minTwoDigits = (n: number) => {
  return (n < 10 ? '0' : '') + n;
};

const roundNumber = (value: number, digits: number) => {
  const multiplier = Math.pow(10, digits);

  return Math.round(value * multiplier) / multiplier;
};

const formatNumber = (num: number) => {
  return num.toLocaleString('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  });
};

export { minTwoDigits, roundNumber, formatNumber };
