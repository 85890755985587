import { DependencyList, useEffect, useRef } from 'react';

import { debounce } from '../utils/debounce';

const useDebounce = (
  callback: Function,
  delay: number,
  deps: DependencyList | undefined = []
) => {
  const debouncedFunction = useRef(debounce(callback, delay));

  useEffect(() => {
    return () => {
      debouncedFunction.current?.cancel();
    };
  }, deps);

  return debouncedFunction.current;
};

export default useDebounce;
