import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface RemoveWalletAddressMutation {
  removeWalletAddress:
    | {
        __typename: 'RemoveWalletAddress';
        address: string;
      }
    | GraphqlErrorType;
}

export interface RemoveWalletAddressMutationVars {
  id: string;
}

const REMOVE_WALLET_ADDRESS = gql`
  mutation RemoveWalletAddress($id: ID!) {
    removeWalletAddress(id: $id) {
      __typename
      ... on RemoveWalletAddress {
        address
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default REMOVE_WALLET_ADDRESS;
