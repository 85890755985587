import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export type StatsOrder = {
  id: string;
  operationType: 'buy' | 'sell';
  orderType: 'limit' | 'market';
  eventId: number;
  eventName: string;
  volume: number;
  orderUniqueId: string;
  status: 'full' | 'partial' | 'cancelled';
  quantity: number;
  executedQuantity: number;
  tokenName: string;
  orderPrice: number;
  executionPrice: number;
  createdAt: string;
  closed: boolean;
};

export interface GetStatsLiveOrdersQuery {
  getStatsLiveOrders:
    | {
        __typename: 'GetStatsLiveOrders';
        orders: StatsOrder[];
        ordersCount: string;
      }
    | GraphqlErrorType;
}

export type GetStatsLiveOrdersQueryVars = {
  orderType: 'limit' | 'market';
  sort?: {
    isActive?: boolean;
    page: number;
    sortDirection?: string;
  };
};

const GET_STATS_ORDERS = gql`
  query GetStatsLiveOrders(
    $orderType: String!
    $sort: GetStatsLiveOrdersSortQuery
  ) {
    getStatsLiveOrders(orderType: $orderType, sort: $sort) {
      __typename
      ... on GetStatsLiveOrders {
        orders {
          id
          operationType
          orderType
          eventId
          eventName
          volume
          orderUniqueId
          status
          quantity
          executedQuantity
          tokenName
          orderPrice
          executionPrice
          createdAt
          closed
        }
        ordersCount
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_STATS_ORDERS;
