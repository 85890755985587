import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface RemoveLiveLimitBidMutation {
  removeLiveLimitBid:
    | {
        __typename: 'RemoveLiveLimitBid';
        bid: {
          id: string;
        };
      }
    | GraphqlErrorType;
}

export interface RemoveLiveLimitBidMutationVars {
  id: string;
}

const REMOVE_LIVE_LIMIT_BID = gql`
  mutation RemoveLiveLimitBid($id: ID!) {
    removeLiveLimitBid(id: $id) {
      __typename
      ... on RemoveLiveLimitBid {
        bid {
          id
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default REMOVE_LIVE_LIMIT_BID;
