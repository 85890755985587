import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

import { ReviewInfoType } from './get-moderation-news-list';

export interface StartNewsReviewMutation {
  startNewsReview:
    | {
        __typename: 'StartNewsReview';
        newsId: string;
        reviewInfo: ReviewInfoType;
      }
    | GraphqlErrorType;
}

export interface StartNewsReviewMutationVars {
  id: string;
}

const START_NEWS_REVIEW = gql`
  mutation StartNewsReview($id: ID!) {
    startNewsReview(id: $id) {
      ... on StartNewsReview {
        newsId
        reviewInfo {
          id
          status
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default START_NEWS_REVIEW;
