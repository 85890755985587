import React, { createContext } from 'react';

type RadioGroupContextProps<V> = {
  disabled?: boolean;
  value?: V;
  onChange?: (value: V) => void;
};

const RadioGroupContext = createContext<RadioGroupContextProps<any>>({});

export default RadioGroupContext;
