import cx from 'classnames';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isValidImageFile,
  isValidVideoFile,
} from 'application/utils/validations';
import { Button, Toast } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';

import css from './news-preview-upload.module.scss';
import NewsPreviewVideo from '../news-preview-video';

interface NewsPreviewProps {
  previewImage: string;
  previewVideo: string;
  uploadPreviewImage: (img: File) => void;
  uploadPreviewVideo: (img: File) => void;
  saveDescription: (description: string) => void;
  descriptionValue: string;
}

const NewsPreviewUpload: React.FC<NewsPreviewProps> = ({
  previewImage,
  previewVideo,
  descriptionValue,
  saveDescription,
  uploadPreviewImage,
  uploadPreviewVideo,
}) => {
  const { t } = useTranslation(['news', 'common']);
  const previewImageInput = useRef<HTMLInputElement>(null);

  const submitPreviewImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.type.startsWith('image/')) {
      const isImage = isValidImageFile(file);
      isImage && uploadPreviewImage(file);
    } else if (file.type.startsWith('video/')) {
      const isVideo = isValidVideoFile(file);
      isVideo && uploadPreviewVideo(file);
    } else {
      Toast.error(t('common:errors:file_not_supported'));
      return;
    }
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    saveDescription(event.target.value);
  };

  const dynamicLabel = previewImage
    ? t('news:labels:image_preview_description')
    : previewVideo
    ? t('news:labels:video_preview_description')
    : '';

  return (
    <Flex flexDirection="column" gap={GridGap.x1}>
      <p className={css.Description}>
        {t('news:labels:preview_upload_description')} <br />
        {t('news:labels:max_preview_size')}
      </p>

      <Button
        onClick={() => previewImageInput.current?.click()}
        className={css.UploadButton}
      >
        {t('common:actions:upload')}
      </Button>

      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/webp, video/mp4"
        onChange={submitPreviewImage}
        ref={previewImageInput}
        className={css.Input}
      />

      {(previewImage || previewVideo) && (
        <>
          {previewImage && (
            <img
              src={previewImage}
              className={css.PreviewImage}
              alt={t('common:alt:preview_image')}
            />
          )}
          {previewVideo && <NewsPreviewVideo src={previewVideo} />}
          <Flex
            flexDirection="column"
            gap={GridGap.x1}
            className={cx(css.Container)}
          >
            {dynamicLabel && <p className={css.Title}>{dynamicLabel}</p>}

            <textarea
              className={css.Textarea}
              value={descriptionValue}
              onChange={handleDescriptionChange}
              style={{ maxHeight: '100px' }}
            />
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default NewsPreviewUpload;
