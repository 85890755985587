import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface LikeNewsMutation {
  likeNews:
    | {
        __typename: 'LikeNews';
        like: {
          newsId: string;
          isLiked: boolean;
          likesCount: number;
        };
      }
    | GraphqlErrorType;
}

export interface LikeNewsMutationVars {
  id: string;
}

const LIKE_NEWS = gql`
  mutation LikeNews($id: ID!) {
    likeNews(newsId: $id) {
      ... on LikeNews {
        like {
          newsId
          isLiked
          likesCount
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default LIKE_NEWS;
