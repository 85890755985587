import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_NEWS_LIST, { GetNewsListQueryVars } from '../graphql/get-news-list';
import UPDATE_PUBLISHED_NEWS, {
  UpdatePublishedNewsMutation,
  UpdatePublishedNewsMutationVars,
} from '../graphql/update-published-news';
import { NewsFormPayloadType } from '../types/news-form-payload-type';
import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

const useUpdatePublishedNews = (newsListVariables?: GetNewsListQueryVars) => {
  const { t } = useTranslation(['news']);
  const [updatePublishedNewsMutation] = useMutation<
    UpdatePublishedNewsMutation,
    UpdatePublishedNewsMutationVars
  >(UPDATE_PUBLISHED_NEWS, {
    update: (cache, { data }) => {
      if (
        data?.updatePublishedNews.__typename !== 'UpdatePublishedNews' ||
        !newsListVariables
      ) {
        return;
      }

      const updatedNews = data.updatePublishedNews.news;

      const newsList: {
        getNewsList: { news: OnePublishedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
      });

      if (!newsList || !updatedNews.id) return;

      const updatedNewsList = newsList.getNewsList.news.map((news) =>
        news.id === updatedNews.id ? updatedNews : news
      );

      cache.writeQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
        data: {
          getNewsList: {
            ...newsList.getNewsList,
            news: updatedNewsList,
          },
        },
      });
    },
  });

  return async (id: string, input: NewsFormPayloadType) => {
    try {
      const { data, errors } = await updatePublishedNewsMutation({
        variables: { id, input },
      });

      const isUpdatePublishedNewsSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isUpdatePublishedNewsSuccess) return;

      if (data?.updatePublishedNews.__typename === 'Error') {
        Toast.error(data.updatePublishedNews.error);
        return;
      }

      if (data?.updatePublishedNews.__typename === 'UpdatePublishedNews') {
        return data.updatePublishedNews.news;
      }
    } catch (e: unknown) {
      Toast.error(t('news:errors:article_edit_error'));
    }
  };
};

export default useUpdatePublishedNews;
