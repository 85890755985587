import cx from 'classnames';
import React from 'react';

import { formatDateAsNumbers } from 'application/utils/date';
import { Flex } from 'ui/flex';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { ArrowDownIcon } from 'ui/icons';

import css from './moderated-news.module.scss';
import { OneModeratedNewsDataType } from '../../graphql/get-moderation-news-list';
import { ModeratedNewsListReviewingStatusesType } from '../../hooks/use-set-moderated-news-list-filters';
import { MODERATED_NEWS_LIST_TABLE_GRID } from '../moderated-news-list/moderated-news-list';
import UnpublishedNewsPreview from '../unpublished-news-preview';

interface ModeratedNewsProps {
  news: OneModeratedNewsDataType;
  openModeratedNews: () => void;
}

const ModeratedNewsListItem: React.FC<ModeratedNewsProps> = ({
  news,
  openModeratedNews,
}) => {
  const {
    id,
    sport,
    category,
    date,
    title,
    previewDescription,
    previewUploadDescription,
    previewImage,
    previewVideo,
    reviewInfo,
    client,
  } = news;
  const [openModeratedNewsPreview, setOpenModeratedNewsPreview] =
    React.useState(false);

  const statusClasses = cx(
    css.ReviewStatus,
    reviewInfo.id === ModeratedNewsListReviewingStatusesType.WAITING &&
      css.WaitingModeration,
    reviewInfo.id === ModeratedNewsListReviewingStatusesType.MY && css.MyReview,
    reviewInfo.id === ModeratedNewsListReviewingStatusesType.OTHER &&
      css.OtherReview
  );

  return (
    <ul className={css.Root}>
      <Grid
        gridTemplateColumns={MODERATED_NEWS_LIST_TABLE_GRID}
        gap={GridGap.x1}
      >
        <li className={css.Date}>{formatDateAsNumbers(new Date(date))}</li>
        <li className={css.Title}>{title}</li>
        <li>
          <Flex alignItems="center" gap={GridGap.x1}>
            <img src={sport.icon} alt={sport.name} width={30} height={30} />
            <p className={css.Label}>{sport.name}</p>
          </Flex>
        </li>
        <li className={css.Label}>{category.name}</li>
        <li className={css.Author}>{client.name}</li>
        <li className={css.ReviewStatusContainer}>
          <p className={statusClasses}>{reviewInfo.status}</p>
        </li>
        {reviewInfo.id !== ModeratedNewsListReviewingStatusesType.OTHER && (
          <li
            className={css.Arrow}
            onClick={() =>
              setOpenModeratedNewsPreview(!openModeratedNewsPreview)
            }
          >
            <ArrowDownIcon
              className={cx(openModeratedNewsPreview && css.ArrowUp)}
            />
          </li>
        )}
      </Grid>

      {openModeratedNewsPreview && (
        <UnpublishedNewsPreview
          newsData={{
            id,
            sport,
            category,
            status: '',
            date,
            title,
            previewDescription,
            previewImage,
            previewVideo,
            previewUploadDescription,
          }}
          openUnpublishedNews={openModeratedNews}
        />
      )}
    </ul>
  );
};

export default ModeratedNewsListItem;
