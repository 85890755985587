import { useRouter } from 'next/router';

import {
  ModeratedNewsListDateSortType,
  ModeratedNewsListFiltersType,
  ModeratedNewsListReviewingStatusesType,
} from './use-set-moderated-news-list-filters';

export const useGetModeratedNewsFilters = (): ModeratedNewsListFiltersType => {
  const router = useRouter();

  return {
    sports: router.query.sports ? String(router.query.sports).split('-') : [],
    categories: router.query.categories
      ? String(router.query.categories).split('-')
      : [],
    status:
      (router.query.status as ModeratedNewsListReviewingStatusesType) ||
      ModeratedNewsListReviewingStatusesType.ALL,
    sort:
      (router.query.sort as ModeratedNewsListDateSortType) ||
      ModeratedNewsListDateSortType.LAST,
    pageNumber: String(router.query.pageNumber || '1'),
  };
};
