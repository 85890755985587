import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface GetMyIqRatingQuery {
  getMyIqRating:
    | {
        __typename: 'GetMyIqRating';
        rating: number;
        lose: number;
        win: number;
        position: number;
      }
    | GraphqlErrorType;
}

export interface GetMyIqRatingQueryVars {}

const GET_MY_IQ_RATING = gql`
  query GetMyIqRating {
    getMyIqRating {
      __typename
      ... on GetMyIqRating {
        rating
        lose
        win
        position
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_MY_IQ_RATING;
