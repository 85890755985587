import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { EmitterEvents } from 'application/event-emitter';
import { verifyApolloQueryResponse } from 'application/graphql/utils';
import WebApp from 'application/web-app';
import { Toast } from 'ui';

import GET_MY_UNPUBLISHED_NEWS_LIST, {
  GetMyUnpublishedNewsListQuery,
} from '../graphql/get-my-unpublished-news-list';

const useGetUnpublishedNews = () => {
  const { data, error, refetch } = useQuery<GetMyUnpublishedNewsListQuery>(
    GET_MY_UNPUBLISHED_NEWS_LIST
  );
  const [unpublishedNewsId, setUnpublishedNewsId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (error) {
      verifyApolloQueryResponse(data, error);
      return;
    }

    if (data?.getMyUnpublishedNewsList.__typename === 'Error') {
      Toast.error(data.getMyUnpublishedNewsList.error);
    }
  }, [data, error]);

  useEffect(() => {
    const handleModalClose = () => refetch();

    WebApp.eventEmitter.subscribeOnEvent(
      EmitterEvents.CREATE_NEWS_MODAL_CLOSE,
      handleModalClose
    );

    return () => {
      WebApp.eventEmitter.unsubscribeOnEvent(
        EmitterEvents.CREATE_NEWS_MODAL_CLOSE,
        handleModalClose
      );
    };
  }, [refetch]);

  return {
    data,
    unpublishedNewsId,
    setUnpublishedNewsId,
    refetch,
  };
};

export default useGetUnpublishedNews;
