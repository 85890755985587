import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Toast } from 'ui';
import { refactorEditorBlocks } from 'ui/editor/refactor-editor-blocks';

import { NewsRejectedReason } from '../../graphql/get-news-rejected-reason-list';
import useCancelNewsReview from '../../hooks/use-cancel-news-review';
import useEditNews from '../../hooks/use-edit-news';
import { useGetModeratedNewsFilters } from '../../hooks/use-get-moderated-news-filters';
import useGetOneModeratedNews from '../../hooks/use-get-one-moderated-news';
import useLoadWidgetData from '../../hooks/use-load-widget-data';
import useModeratePublishedNews from '../../hooks/use-moderate-news-publication';
import useStartNewsReview from '../../hooks/use-start-news-review';
import useUpdateModeratedNews from '../../hooks/use-update-moderated-news';
import useUploadImage from '../../hooks/use-upload-image';
import useUploadVideo from '../../hooks/use-upload-video';
import EditModeratedNewsModal from '../edit-moderated-news-modal';
import RejectConfirmModal from '../reject-news-confirm-modal';

export type RejectedReason = NewsRejectedReason | null;

interface ModeratedNewsModalContainerProps {
  id: string;
  closeModal: () => void;
}

const ModeratedNewsModalContainer: React.FC<
  ModeratedNewsModalContainerProps
> = ({ id, closeModal }) => {
  const { data, error } = useGetOneModeratedNews(id);
  const {
    isInitialDataReady,
    newsFormData,
    updateNewsFormData,
    isNewsDataModified,
  } = useEditNews(data?.news || null);

  const [loading, setLoading] = useState(false);
  const [isRejectConfirmModalOpen, setPublishConfirmModalOpen] =
    useState(false);
  const [myNewsReview, setMyNewsReview] = useState<boolean | null>(null);
  const [rejectedReason, setRejectedReason] = useState<RejectedReason>(null);
  const { t } = useTranslation('news');
  const filters = useGetModeratedNewsFilters();
  const moderateNewsPublication = useModeratePublishedNews(filters);
  const startNewsReview = useStartNewsReview(filters);
  const cancelNewsReview = useCancelNewsReview(filters);
  const updateModeratedNewsMutation = useUpdateModeratedNews(filters);
  const loadWidgetLink = useLoadWidgetData();
  const uploadImage = useUploadImage();
  const uploadVideo = useUploadVideo();

  useEffect(() => {
    if (data && myNewsReview === null) {
      setMyNewsReview(data.myReview);
    }
  }, [data]);

  const uploadPreviewImage = async (img: File) => {
    const result = await uploadImage(img);

    if (result.success === 1) {
      updateNewsFormData('previewImage', result.file.url);
    }
    return result;
  };

  const uploadPreviewVideo = async (video: File) => {
    const result = await uploadVideo(video);

    if (result.success === 1) {
      updateNewsFormData('previewVideo', result.file.url);
    }
    return result;
  };

  const updateModeratedNews = async (showConfirm = true) => {
    if (!newsFormData.sportId || !newsFormData.categoryId) return false;

    if (showConfirm) {
      const confirmUpdateNews = window.confirm('Хотите сохранить изменения?');
      if (!confirmUpdateNews) return false;
    }

    setLoading(true);
    const input = {
      sportId: newsFormData.sportId,
      categoryId: newsFormData.categoryId,
      title: newsFormData.title,
      previewDescription: newsFormData.previewDescription,
      previewUploadDescription: newsFormData.previewUploadDescription,
      previewImage: newsFormData.previewImage,
      previewVideo: newsFormData.previewVideo,
      blocks: refactorEditorBlocks(newsFormData.blocks),
    };

    const result = await updateModeratedNewsMutation(id, input);
    setLoading(false);

    if (!result) return false;

    showConfirm && Toast.success(t('news:successes:article_update_success'));
    closeModal();

    return true;
  };

  const publishNews = async () => {
    const confirmPublishNews = window.confirm(
      t('news:actions:publish_moderated_article_disclaimer')
    );
    if (!confirmPublishNews) return;

    setLoading(true);

    const saveResult = await updateModeratedNews(false);

    if (saveResult) {
      const result = await moderateNewsPublication({
        moderateNewsPublicationId: id,
        isPublished: true,
      });

      if (result) {
        closeModal();
      }
    }

    setLoading(false);
  };

  const rejectNews = async () => {
    if (!rejectedReason?.reason) return;

    setLoading(true);
    const result = await moderateNewsPublication({
      moderateNewsPublicationId: id,
      isPublished: false,
      reasonData: rejectedReason,
    });

    setLoading(false);
    if (result) {
      closeModal();
    }
  };

  const setNewsReview = () => {
    setMyNewsReview(!myNewsReview);

    if (!myNewsReview) {
      startNewsReview(id);
    } else {
      cancelNewsReview(id);
    }
  };

  if (!isInitialDataReady || error || myNewsReview === null) return null;

  return (
    <>
      <EditModeratedNewsModal
        loading={loading}
        newsFormData={newsFormData}
        uploadImage={uploadImage}
        uploadVideo={uploadVideo}
        uploadPreviewImage={uploadPreviewImage}
        uploadPreviewVideo={uploadPreviewVideo}
        loadNewsWidgetLink={loadWidgetLink}
        isNewsDataModified={isNewsDataModified}
        updateModeratedNews={updateModeratedNews}
        updateNewsFormData={updateNewsFormData}
        publishNews={publishNews}
        setNewsReview={setNewsReview}
        myNewsReview={myNewsReview}
        rejectNews={() => setPublishConfirmModalOpen(true)}
        closeModal={closeModal}
      />

      {isRejectConfirmModalOpen && (
        <RejectConfirmModal
          closeModal={() => setPublishConfirmModalOpen(false)}
          isModalOpen={isRejectConfirmModalOpen}
          loading={loading}
          rejectedReason={rejectedReason}
          setRejectedReason={setRejectedReason}
          rejectNews={rejectNews}
        />
      )}
    </>
  );
};

export default ModeratedNewsModalContainer;
