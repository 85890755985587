import { useRouter } from 'next/router';
import React, { MutableRefObject, useState } from 'react';

import { useAppModals } from 'application/context/app-modals-provider';
import { useUser } from 'application/context/user-provider';

import PublishedNewsPreview from './published-news-preview';
import useDeletePublishedNews from '../../hooks/use-delete-published-news';
import { useGetNewsFeedFilters } from '../../hooks/use-get-news-feed-filters';
import useLikeNews from '../../hooks/use-like-news';
import useUpdateBookmarksNews from '../../hooks/use-update-bookmarks-news';
import DeleteNewsModal from '../../modals/delete-news-modal';
import { ComponentOrigins } from '../../types/component-origins';
import { OnePublishedNewsDataType } from '../../types/one-published-news-data-type';
import EditPublishedNewsModal from '../edit-published-news-modal';

interface Props {
  triggerRef?: MutableRefObject<HTMLDivElement | null>;
  news: Omit<OnePublishedNewsDataType, 'blocks'>;
  componentOrigin: ComponentOrigins;
  isPinned?: boolean;
}

const PublishedNewsPreviewContainer = ({
  news,
  componentOrigin,
  isPinned,
  triggerRef,
}: Props) => {
  const { id, likesCount, isLiked } = news;

  const { setSignInOpen } = useAppModals();

  const [isDeleteNewsModalOpen, setDeleteNewsModalOpen] = useState(false);
  const [isEditNewsModalOpen, setEditNewsModalOpen] = useState(false);
  const newsFeedFilters = useGetNewsFeedFilters();
  const addNewsInBookmarksMutation = useUpdateBookmarksNews(
    componentOrigin,
    newsFeedFilters
  );
  const likeNewsMutation = useLikeNews(componentOrigin, newsFeedFilters);
  const deleteNewsMutation = useDeletePublishedNews(newsFeedFilters);
  const router = useRouter();
  const { user } = useUser();
  const editor =
    (componentOrigin !== ComponentOrigins.BOOKMARKS_LIST && user?.editor) ||
    false;

  const openNews = () => {
    router.push(`/news/${id}`);
  };

  const showComments = () => {
    localStorage.setItem('scrollTo', 'comments-section');
    router.push(`/news/${id}`);
  };

  const likeNews = () => {
    if (!user) {
      setSignInOpen(true);
      return;
    }

    const newLikesCount = !isLiked
      ? Number(likesCount) + 1
      : Math.max(0, Number(likesCount) - 1);
    likeNewsMutation(id, !isLiked, newLikesCount);
  };

  const addNewsInBookmarks = () => {
    if (!user) {
      setSignInOpen(true);
      return;
    }

    addNewsInBookmarksMutation(id);
  };

  const deleteNews = async () => {
    const result = await deleteNewsMutation(id);
    if (result) {
      setDeleteNewsModalOpen(false);
    }
  };

  return (
    <>
      <PublishedNewsPreview
        isPinned={isPinned}
        news={news}
        editor={editor}
        openNews={openNews}
        likeNews={likeNews}
        deleteNews={() => setDeleteNewsModalOpen(true)}
        editNews={() => setEditNewsModalOpen(true)}
        showComments={showComments}
        addToBookmarks={addNewsInBookmarks}
        triggerRef={triggerRef}
      />
      {isDeleteNewsModalOpen && (
        <DeleteNewsModal
          title={news.title}
          isModalOpen={isDeleteNewsModalOpen}
          closeModal={() => setDeleteNewsModalOpen(false)}
          callback={deleteNews}
        />
      )}
      {isEditNewsModalOpen && (
        <EditPublishedNewsModal
          id={id}
          closeModal={() => setEditNewsModalOpen(false)}
        />
      )}
    </>
  );
};

export default PublishedNewsPreviewContainer;
