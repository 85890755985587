import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { verifyApolloMutationResponse } from 'application/graphql/utils';
import { Toast } from 'ui';

import GET_BOOKMARKS_NEWS_LIST, {
  BookmarksNews,
} from '../graphql/get-bookmarks-news-list';
import GET_NEWS_LIST, { GetNewsListQueryVars } from '../graphql/get-news-list';
import GET_ONE_PUBLISHED_NEWS_FOOTER_DATA from '../graphql/get-one-published-news-footer-data';
import GET_CLIENT_COUNTS from '../graphql/get-сlient-сounts';
import UPDATE_BOOKMARKS_NEWS, {
  UpdateBookmarksNewsMutationVars,
  UpdateMyFavoriteNewsMutation,
} from '../graphql/update-bookmarks-news';
import { ComponentOrigins } from '../types/component-origins';
import { OnePublishedNewsDataType } from '../types/one-published-news-data-type';

export const useUpdateBookmarksNews = (
  componentOrigin: ComponentOrigins,
  newsListVariables?: GetNewsListQueryVars
) => {
  const { t } = useTranslation(['news']);
  const [updateBookmarksNewsMutation] = useMutation<
    UpdateMyFavoriteNewsMutation,
    UpdateBookmarksNewsMutationVars
  >(UPDATE_BOOKMARKS_NEWS, {
    refetchQueries: (mutationResult) => {
      if (
        mutationResult?.data?.updateBookmarksNews.__typename !==
        'UpdateBookmarksNews'
      ) {
        return [];
      }

      if (componentOrigin === ComponentOrigins.BOOKMARKS_LIST) {
        return [GET_CLIENT_COUNTS];
      }

      if (componentOrigin === ComponentOrigins.ONE_NEWS) {
        const { id } = mutationResult?.data?.updateBookmarksNews?.news;
        return [
          {
            query: GET_ONE_PUBLISHED_NEWS_FOOTER_DATA,
            variables: { id },
          },
        ];
      }

      return [];
    },
    update(cache, { data }) {
      if (data?.updateBookmarksNews.__typename !== 'UpdateBookmarksNews') {
        return;
      }

      const { bookmarksInfo, id } = data.updateBookmarksNews.news;
      if (!id) return;

      const bookmarksNewsList: {
        getBookmarksNewsList: { news: BookmarksNews[] };
      } | null = cache.readQuery({
        query: GET_BOOKMARKS_NEWS_LIST,
      });

      if (bookmarksNewsList && !bookmarksInfo.inBookmarks) {
        const updatedBookmarksNewsList =
          bookmarksNewsList.getBookmarksNewsList.news.filter(
            (news) => news.id !== id
          );

        cache.writeQuery({
          query: GET_BOOKMARKS_NEWS_LIST,
          data: {
            getBookmarksNewsList: {
              ...bookmarksNewsList.getBookmarksNewsList,
              news: updatedBookmarksNewsList,
            },
          },
        });
      }

      if (!newsListVariables) return;

      const newsList: {
        getNewsList: { news: OnePublishedNewsDataType[] };
      } | null = cache.readQuery({
        query: GET_NEWS_LIST,
        variables: newsListVariables,
      });

      if (!newsList) return;

      const newsToUpdate = newsList.getNewsList.news.find(
        (news) => news.id === id
      );

      if (!newsToUpdate) return;

      cache.modify({
        id: cache.identify(newsToUpdate),
        fields: {
          bookmarksInfo() {
            return bookmarksInfo;
          },
        },
      });
    },
  });

  return async (newsId: string) => {
    try {
      const { data, errors } = await updateBookmarksNewsMutation({
        variables: { newsId },
      });

      const isUpdateMyFavoriteNewsSuccess = verifyApolloMutationResponse(
        data,
        errors
      );
      if (!isUpdateMyFavoriteNewsSuccess) return;

      if (data?.updateBookmarksNews.__typename === 'Error') {
        Toast.error(data.updateBookmarksNews.error);
        return;
      }

      if (data?.updateBookmarksNews.__typename === 'UpdateBookmarksNews') {
        const { bookmarksInfo, id } = data.updateBookmarksNews.news;
        Toast.info(
          t(
            bookmarksInfo.inBookmarks
              ? 'news:successes:article_add_in_bookmarks_success'
              : 'news:successes:article_delete_in_bookmarks_success'
          )
        );
        return { bookmarksInfo, id };
      }
    } catch (e: unknown) {
      Toast.error(t('common:errors:something_went_wrong'));
    }
  };
};

export default useUpdateBookmarksNews;
