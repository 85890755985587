import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface UploadClientAvatarMutation {
  uploadClientAvatar:
    | {
        __typename: 'UploadClientAvatar';
        fileUrl: string;
      }
    | GraphqlErrorType;
}

export interface UploadClientAvatarMutationVars {
  file: File;
}

const UPLOAD_CLIENT_AVATAR = gql`
  mutation UploadClientAvatar($file: Upload!) {
    uploadClientAvatar(file: $file) {
      __typename
      ... on UploadClientAvatar {
        fileUrl
      }
      ... on Error {
        error
      }
    }
  }
`;

export default UPLOAD_CLIENT_AVATAR;
