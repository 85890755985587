import { gql } from '@apollo/client';

import { GraphqlErrorType } from 'application/graphql/graphql-types';

export const PAGE_LIMIT_LIVE = 2;

export type StatsOutcome = {
  id: string;
  name: string;
  expiration: boolean;
  quantity: number;
  orderPnl: number;
  win?: boolean | null;
  currentPrice: number;
  unrealisedPnl: number;
  allPnl: number;
};

export type StatsEvent = {
  id: string;
  name: string;
  startDate: string;
  outcomes: StatsOutcome[];
  tournamentId: string;
};

export interface GetStatsByEventsQuery {
  getStatsByEvents:
    | {
        __typename: 'GetStatsByEvents';
        events: StatsEvent[];
        nav: {
          limit: number;
          offset: number;
          hasNext: boolean;
        };
      }
    | GraphqlErrorType;
}

export type GetStatsByEventsQueryVars = {
  nav: {
    limit: number;
    offset: number;
  };
  sort: {
    eventStartDate?: 'ASC' | 'DESC';
  };
};

const GET_STATS_EVENTS = gql`
  query GetStatsByEvents(
    $nav: GetStatsLiveEventsNavQuery!
    $sort: GetStatsLiveEventsSortQuery!
  ) {
    getStatsByEvents(nav: $nav, sort: $sort) {
      __typename
      ... on GetStatsByEvents {
        events {
          id
          name
          startDate
          tournamentId
          outcomes {
            id
            name
            expiration
            quantity
            orderPnl
            win
            currentPrice
            unrealisedPnl
            allPnl
          }
        }
        nav {
          limit
          offset
          hasNext
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_STATS_EVENTS;
