import React, { useEffect } from 'react';

import NewsSportSelect from './news-sport-select';
import useGetEventSportsList from '../../hooks/use-get-event-sports-list';

interface NewsSportSelectContainerProps {
  sportId: string | null;
  sportIdChange: (sportId: string) => void;
}

const NewsSportSelectContainer: React.FC<NewsSportSelectContainerProps> = ({
  sportId,
  sportIdChange,
}) => {
  const sportsList = useGetEventSportsList();

  useEffect(() => {
    if (sportsList && !sportId) {
      sportIdChange(sportsList[0].id);
    }
  }, [sportsList]);

  if (!sportsList || !sportId) {
    return null;
  }

  return (
    <NewsSportSelect
      sport={sportId}
      sports={sportsList}
      sportChange={sportIdChange}
    />
  );
};

export default NewsSportSelectContainer;
