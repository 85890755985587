import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui';

interface ConnectAddressProps {
  onClick: () => void;
  disabled: boolean;
}

const ConnectAddress: React.FC<ConnectAddressProps> = ({
  onClick,
  disabled,
}) => {
  const { t } = useTranslation(['wallets']);

  return (
    <Button onClick={onClick} disabled={disabled}>
      {t('connect_address')}
    </Button>
  );
};

export default ConnectAddress;
