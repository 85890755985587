import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface UpdateWalletAddressConnectionCodeMutation {
  updateWalletAddressConnectionCode:
    | {
        __typename: 'UpdateWalletAddressConnectionCode';
        code: string;
      }
    | GraphqlErrorType;
}

export interface UpdateWalletAddressConnectionCodeMutationVars {
  address: string;
}

const UPDATE_WALLET_ADDRESS_CONNECTION_CODE = gql`
  mutation UpdateWalletAddressConnectionCode($address: String!) {
    updateWalletAddressConnectionCode(address: $address) {
      __typename
      ... on UpdateWalletAddressConnectionCode {
        code
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default UPDATE_WALLET_ADDRESS_CONNECTION_CODE;
